import React, { useEffect, useState } from "react";
import { API } from "../../../API";
import { toast } from "react-toastify";

const EditStudentSubAdminModel = ({ data, show, close, reload }) => {
    const [isLoading, setIsLoading] = useState(false);

    const [branch, setBranch] = useState([]);
    const [country, setCountry] = useState([]);
    const [course, setCourse] = useState([]);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phoneNo: "",
        branchId: "",
        country: "",
        paymentAmount: 0,
    });

    useEffect(() => {
        setFormData({
            name: data.name,
            email: data.email,
            phoneNo: data.phoneNo,
            courseId: data.courseId?._id,
            country: data.country?._id,
            paymentAmount: data.paymentAmount || 0,
        });
    }, [data]);

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    const getAllCountry = async (id) => {
        try {
            const { data } = await API.get(`/country/by-branch/${id}`);
            setCountry(data.data.countries);
        } catch (error) {
            console.log("error in getAllCountry :", error);
        }
    };

    const getAllCourse = async (id) => {
        try {
            if (id) {
                const { data } = await API.get(`/course/get/${id}`);
                setCourse(data.data.course);
            }
        } catch (error) {
            console.log("error in getAllCourse :", error);
        }
    };

    useEffect(() => {
        if (data?.branchId?._id) {
            getAllCountry(data?.branchId?._id);
            getAllCourse(data?.branchId?._id);
        }
    }, [data?.branchId?._id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const res = await API.put(
                `/user/update-student/${data?._id}`,
                formData
            );
            if (res.data.success) {
                toast.success(res.data.message);
            } else {
                toast.error(res.data.message);
            }
            setFormData({
                name: "",
                email: "",
                phoneNo: "",
                courseId: "",
                country: "",
                paymentAmount: 0,
            });
            reload();
            close(false);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error(error.response.data.message);
            console.log("error in add branch", error);
        }
    };

    return (
        <div className={`${show ? "" : "hidden"}`}>
            <div class="justify-center items-center flex overflow-x-hidden overflow-y-auto  fixed inset-0 z-50 outline-none focus:outline-none">
                <div class="w-72 sm:w-96 xl:w-1/3 my-6 mx-auto max-w-2xl relative bg-white  rounded-2xl flex flex-col">
                    <div class="bg-white w-full rounded-lg  shadow-lg  max-h-[80vh] overflow-y-auto relative">
                        <div class="items-center flex justify-between flex-wrap md:flex-nowrap py-2 md:py-0 px-2 font-bold text-center text-lg md:text-2xl  bg-primary sticky top-0 m-3 rounded">
                            <h2 class="grow text-lg lg:text-2xl font-bold text-white  text-center">
                                <span class="bg-gradient-to-r  bg-clip-text">
                                    Add Student
                                </span>
                            </h2>
                            <button
                                type="button"
                                onClick={() => close(false)}
                                class=" rounded-md md:p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black"
                            >
                                <svg
                                    class="h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                    style={{ color: "white" }}
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    ></path>
                                </svg>
                            </button>
                        </div>
                        <form
                            class="flex justify-center items-center"
                            onSubmit={handleSubmit}
                        >
                            <div class="space-y-2 px-4 pt-2 pb-4 w-full ">
                                <div>
                                    <label
                                        for="name"
                                        class="block mb-2 text-sm font-medium text-gray-900 "
                                    >
                                        Student Name
                                    </label>
                                    <input
                                        onChange={handleChange}
                                        defaultValue={formData?.name}
                                        type="text"
                                        name="name"
                                        id="name"
                                        autoComplete="off"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                        placeholder="Student name"
                                    />
                                </div>
                                <div>
                                    <label
                                        for="email"
                                        class="block mb-2 text-sm font-medium text-gray-900 "
                                    >
                                        Student Email
                                    </label>
                                    <input
                                        onChange={handleChange}
                                        defaultValue={formData?.email}
                                        type="email"
                                        name="email"
                                        id="email"
                                        autoComplete="off"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                        placeholder="Student email"
                                    />
                                </div>
                                <div className="flex justify-between gap-4">
                                    <div className="w-full">
                                        <label
                                            for="phoneNo"
                                            class="block mb-2 text-sm font-medium text-gray-900 "
                                        >
                                            Student Phone Number
                                        </label>
                                        <input
                                            onChange={handleChange}
                                            defaultValue={formData?.phoneNo}
                                            type="number"
                                            name="phoneNo"
                                            id="phoneNo"
                                            autoComplete="off"
                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                            placeholder="Student phone number"
                                        />
                                    </div>

                                    <div className="w-full">
                                        <label
                                            htmlFor="paymentAmount"
                                            className="block mb-2 text-sm font-medium text-gray-900"
                                        >
                                            Payment Amount
                                        </label>
                                        <input
                                            onChange={handleChange}
                                            value={formData?.paymentAmount}
                                            type="number"
                                            name="paymentAmount"
                                            id="paymentAmount"
                                            autoComplete="off"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                            placeholder="Payment Amount"
                                        />
                                    </div>
                                </div>

                                <div class="col-span-2">
                                    <label
                                        for="address"
                                        class="block mb-2 text-sm font-medium text-gray-900 "
                                    >
                                        Country
                                    </label>
                                    <select
                                        onChange={handleChange}
                                        value={formData?.country}
                                        name="country"
                                        id="country"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                    >
                                        {country &&
                                            country?.map((c, i) => (
                                                <option key={i} value={c?._id}>
                                                    {c?.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <div>
                                    <label
                                        for="courseId"
                                        class="block mb-2 text-sm font-medium text-gray-900 "
                                    >
                                        Course
                                    </label>
                                    <select
                                        onChange={handleChange}
                                        value={formData?.courseId}
                                        name="courseId"
                                        id="courseId"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                    >
                                        {course &&
                                            course?.map((c, i) => (
                                                <option key={i} value={c?._id}>
                                                    {c?.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>

                                <div class="flex items-center justify-center">
                                    <button
                                        type="submit"
                                        class="bg-primary  text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline w-fit"
                                    >
                                        {isLoading ? (
                                            <div class="flex justify-center items-center">
                                                <div class="w-5 h-5 rounded-full border-4 border-gray-400 border-t-white animate-spin"></div>
                                            </div>
                                        ) : (
                                            <span>Save Student</span>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="opacity-70 fixed inset-0 z-40 bg-black"></div>
        </div>
    );
};

export default EditStudentSubAdminModel;
