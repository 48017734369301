import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API } from "../../API";
import { FaCodeBranch } from "react-icons/fa";
import { GrGroup } from "react-icons/gr";
import { LiaChalkboardTeacherSolid } from "react-icons/lia";
import { PiFrameCorners, PiStudentFill } from "react-icons/pi";
import { GiTeacher } from "react-icons/gi";

const SubAdminDashbord = () => {
  const [dashboardData, setDashboardData] = useState();
  const array = [
    {
      name: "counselors",
      link: "/receptionist-counselors",
      icon: <LiaChalkboardTeacherSolid className="h-12 w-12 text-white" />,
    },
    {
      name: "tutors",
      link: "/receptionist-tutors",
      icon: <GiTeacher className="h-10 w-10 text-white" />,
    },
    {
      name: "students",
      link: "/receptionist-students",
      icon: <PiStudentFill className="h-12 w-12 text-white" />,
    },
    {
      name: "banners",
      link: "/receptionist-banners",
      icon: <PiFrameCorners className="h-12 w-12 text-white" />,
    },
  ];

  const getDashboardData = async () => {
    try {
      const res = await API.get("/user/sub-dashboard");
      if (res.data.success) {
        setDashboardData(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <div className="p-6">
      <div class="grid grid-cols-1 md:grid-cols-5 lg:grid-rows-5 gap-6 mb-6">
        {dashboardData &&
          array.map((item) => (
            <Link
              to={item.link}
              class="bg-white rounded-xl  p-6 shadow-xl hover:shadow-md shadow-black/5 hover:shadow-red-300"
            >
              <div class="flex justify-between my-3">
                <div className="flex justify-between w-full">
                  <div>
                    <div class="text-3xl font-semibold mb-1">
                      {dashboardData[`${item.name}`]}
                    </div>
                    <div class="text-lg font-medium capitalize">
                      {item.name}
                    </div>
                  </div>
                  <div class="flex h-20 w-20 items-center justify-center rounded-full bg-primary">
                    {item?.icon}
                  </div>
                </div>
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default SubAdminDashbord;
