/* eslint-disable jsx-a11y/anchor-is-valid */
import { ToastContainer, toast } from "react-toastify";
import {
  useLocation,
  useHistory,
  useNavigate,
  useParams,
  Link,
} from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { API } from "../API";
import useAuth from "../hooks/useAuth";
import { NavLink } from "react-router-dom";

export const SideBar = () => {
  const location = useLocation();
  const params = useParams();
  const projectRef = useRef();
  const { auth, setAuth } = useAuth();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const handleClicksOutside = (event) => {
      if (!projectRef?.current?.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("click", handleClicksOutside);
    return () => {
      document.removeEventListener("click", handleClicksOutside);
    };
  }, [projectRef]);

  const [state, setState] = useState({
    menu: false,
    isOpen: false,
    homeLinkClass: "nav-item nav-link",
    aboutLinkClass: "nav-item nav-link",
    menuClass: "",
  });

  const [isLoggedInUser, setIsLoggedInUser] = useState({});
  const navigate = useNavigate();
  const authToken = localStorage.getItem("authToken");
  const user = JSON.parse(localStorage.getItem("user"));
  const [pathName, setPathName] = useState("");
  const menuItem = {
    SUPER_ADMIN: [
      {
        name: "home",
        link: "/admin-dashboard",
      },
      {
        name: "branches",
        link: "/admin-branch",
      },
      {
        name: "branch admin",
        link: "/admin-receptionist",
      },
      {
        name: "counselors",
        link: "/admin-counselors",
      },
      {
        name: "tutors",
        link: "/admin-tutors",
      },
      {
        name: "students",
        link: "/admin-students",
      },
      {
        name: "banners",
        link: "/admin-banners",
      },
      {
        name: "course",
        link: "/admin-course",
      },
      {
        name: "country",
        link: "/admin-country",
      },
    ],
    RECEPTIONIST: [
      {
        name: "home",
        link: "/receptionist-dashboard",
      },
      {
        name: "counselors",
        link: "/receptionist-counselors",
      },
      {
        name: "tutors",
        link: "/receptionist-tutors",
      },
      {
        name: "students",
        link: "/receptionist-students",
      },
      {
        name: "banners",
        link: "/receptionist-banners",
      },
      {
        name: "course",
        link: "/receptionist-course",
      },
      {
        name: "country",
        link: "/receptionist-country",
      },
    ],
    COUNSELOR: [
      {
        name: "home",
        link: "/counselor-dashboard",
      },
      {
        name: "session",
        link: "/counselor-session",
      },
    ],
  };
  async function getUser() {
    // const { data } = await API.get(`/user/me`);\
    setIsLoggedInUser(user);

    setAuth({
      ...auth,
      user: user,
      accessToken: authToken,
      role: [user.role],
    });

    setPathName(
      user.role === "SUPER_ADMIN"
        ? "/admin-dashboard"
        : user.role === "RECEPTIONIST"
        ? "/receptionist-dashboard"
        : user.role === "COUNSELOR"
        ? "/counselor-dashboard"
        : "/user-dashboard"
    );
  }

  useEffect(() => {
    if (user) {
      getUser();
    }
  }, []);

  const toggleMenu = () => {
    setState({
      ...state,
      menu: !state.menu,
    });
  };

  const logout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("user");
    setAuth({});
    toast.success("logout successfully.");
    navigate("/login");
  };
  return (
    <>
      <div class="relative flex flex-col bg-white rounded-r-md  text-gray-700 h-screen w-full min-w-[22rem] p-4 shadow-md border-r">
        <div class="mb-2 flex justify-center border-b-2 border-primary">
          <div class="block antialiased tracking-normal font-sans text-xl font-semibold leading-snug text-gray-900">
            <img
              className="h-full w-full max-w-[180px]"
              src="/images/Logo.png"
            />
          </div>
        </div>
        <nav class="flex flex-col gap-1 min-w-[240px] p-2 font-sans text-base font-normal text-gray-700">
          {menuItem[user?.role] &&
            menuItem[user?.role]?.map((item) => {
              return (
                <Link
                  to={item?.link}
                  role="button"
                  tabindex="0"
                  class={`${
                    location.pathname === item?.link
                      ? "text-white bg-primary font-bold"
                      : "hover:bg-gray-100"
                  } flex items-center w-full p-3 capitalize text-lg rounded-lg text-start leading-tight transition-all outline-none`}
                >
                  {item?.name}
                </Link>
              );
            })}

          {/* <div
            onClick={() => logout()}
            role="button"
            tabindex="0"
            class="flex items-center w-full p-3 rounded-lg text-start leading-tight transition-all hover:bg-blue-50 hover:bg-opacity-80 focus:bg-blue-50 focus:bg-opacity-80 active:bg-blue-50 active:bg-opacity-80 hover:text-blue-900 focus:text-blue-900 active:text-blue-900 outline-none"
          >
            <div class="grid place-items-center mr-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                class="h-5 w-5"
              >
                <path
                  fill-rule="evenodd"
                  d="M12 2.25a.75.75 0 01.75.75v9a.75.75 0 01-1.5 0V3a.75.75 0 01.75-.75zM6.166 5.106a.75.75 0 010 1.06 8.25 8.25 0 1011.668 0 .75.75 0 111.06-1.06c3.808 3.807 3.808 9.98 0 13.788-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788a.75.75 0 011.06 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            Log Out
          </div> */}
        </nav>
      </div>
    </>
  );
};
