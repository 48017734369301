import React, { useState } from "react";
import { API } from "../../../API";
import { toast } from "react-toastify";
import DeleteConfirmation from "../../models/DeleteConfirmation";
import EditCountryModelSubAdmin from "../../models/country/EditCountryModelSubAdmin";

const CountryTableSubAdmin = ({ data, reload }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [editPopupShow, setEditPopupShow] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({});

  const deleteBranch = async (id) => {
    try {
      const { data } = await API.delete(`/country/${id}`);
      if (data.success) {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
      setShowConfirmation(false);
      reload();
      setSelectedCountry({});
    } catch (error) {
      toast.error(error.response.data.message);
      console.log("error in delete country:", error);
    }
  };
  return (
    <div>
      <div className="rounded-t text-white flex items-center justify-center mt-4 px-6 py-2 font-bold text-xl border-b border-gray-100 bg-primary">
        Country List
      </div>
      <div className="overflow-x-auto overflow-y-auto relative h-[85vh]">
        <table className="border-collapse table-auto table-striped relative min-w-full divide-y divide-gray-200">
          <thead className="">
            <tr className="sticky top-0 bg-gray-100 text-base font-bold text-gray-500 uppercase  z-10">
              <th
                scope="col"
                className="px-6 py-3 text-left tracking-wider sticky left-0 bg-gray-100"
              >
                No
              </th>
              <th scope="col" className="px-6 py-3 text-left tracking-wider">
                Country Name
              </th>
              <th scope="col" className="px-6 py-3 text-left tracking-wider">
                Branch Name
              </th>
              <th scope="col" className="px-6 py-3 text-left tracking-wider">
                Update
              </th>
              <th scope="col" className="px-6 py-3 text-left tracking-wider">
                Delete
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {data.map((branch, i) => (
              <tr key={i} className="text-lg text-gray-900">
                <td className="px-6 py-4 whitespace-nowrap sticky left-0 bg-white">
                  <div className="flex items-center">
                    <div>{i + 1}</div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div>{branch?.name}</div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div>{branch?.branchId?.name ? branch?.branchId?.name : "-"}</div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span
                    onClick={() => {
                      setSelectedCountry(branch);
                      setEditPopupShow(true);
                    }}
                    className="cursor-pointer inline-flex leading-5 text-white  bg-blue-600 hover:bg-blue-700  font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                  >
                    Update
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span
                    onClick={() => {
                      setSelectedCountry(branch);
                      setShowConfirmation(true);
                    }}
                    className="cursor-pointer inline-flex leading-5 text-white  bg-primary hover:bg-red-700  font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                  >
                    Delete
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showConfirmation && (
        <DeleteConfirmation
          msg={`Are you sure you want to delete ${selectedCountry?.name} branch ?`}
          close={setShowConfirmation}
          task={() => deleteBranch(selectedCountry?._id)}
        />
      )}

      <EditCountryModelSubAdmin
        key={selectedCountry?._id}
        show={editPopupShow}
        close={setEditPopupShow}
        data={selectedCountry}
        reload={reload}
      />
    </div>
  );
};

export default CountryTableSubAdmin;
