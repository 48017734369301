import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API } from "../../API";
import { toast } from "react-toastify";

const SuperAdminProfile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [formData, setFormData] = useState({
    current_password: "",
    new_password: "",
    confirm_new_password: "",
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const changePassword = async (e) => {
    e.preventDefault();
    try {
      if (formData?.new_password !== formData.confirm_new_password) {
        toast.error("Password and confirm password do not match");
        return;
      }
      setIsLoading(true);
      const { data } = await API.post("/user/change-my-password", {
        oldPassword: formData.current_password,
        newPassword: formData.new_password,
      });
      if (data.success) {
        toast.success(data.message);
        document.getElementById("pass_form").reset();
      } else {
        toast.error(data.message);
      }
      setFormData({
        current_password: "",
        new_password: "",
        confirm_new_password: "",
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message);
      console.log("error in changePassword:", error);
    }
  };

  return (
    <div className="p-6">
      <div class="min-h-screen flex flex-col items-center">
        <div class="w-full max-w-4xl mt-10 p-6 bg-white rounded-lg shadow-md">
          <div class="grid grid-cols-2 gap-6">
            <div>
              <h2 class="text-2xl font-bold mb-6">Profile Information</h2>
              <div class="mb-4">
                <label class="block text-sm font-medium text-gray-700">
                  Username
                </label>
                <div class="mt-1">
                  <input
                    type="text"
                    name="username"
                    class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    value={user?.userName}
                    disabled
                  />
                </div>
              </div>
              <div class="mb-4">
                <label class="block text-sm font-medium text-gray-700">
                  Name
                </label>
                <div class="mt-1">
                  <input
                    type="text"
                    name="name"
                    class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    value={user?.name}
                    disabled
                  />
                </div>
              </div>
              <div class="mb-4">
                <label class="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <div class="mt-1">
                  <input
                    type="email"
                    name="email"
                    class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    value={user?.email}
                    disabled
                  />
                </div>
              </div>
              <div class="mb-4">
                <label class="block text-sm font-medium text-gray-700">
                  Phone No
                </label>
                <div class="mt-1">
                  <input
                    type="phoneNo"
                    name="phoneNo"
                    class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                    value={user?.phoneNo}
                    disabled
                  />
                </div>
              </div>
            </div>
            <form id="pass_form" onSubmit={changePassword}>
              <h2 class="text-2xl font-bold mb-6">Change Password</h2>
              <div class="mb-4">
                <label class="block text-sm font-medium text-gray-700">
                  Current Password
                </label>
                <div class="mt-1">
                  <input
                    autoComplete="off"
                    type="password"
                    onChange={handleChange}
                    name="current_password"
                    class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                  />
                </div>
              </div>
              <div class="mb-4">
                <label class="block text-sm font-medium text-gray-700">
                  New Password
                </label>
                <div class="mt-1">
                  <input
                    autoComplete="off"
                    type="password"
                    onChange={handleChange}
                    name="new_password"
                    class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                  />
                </div>
              </div>
              <div class="mb-4">
                <label class="block text-sm font-medium text-gray-700">
                  Confirm New Password
                </label>
                <div class="mt-1">
                  <input
                    autoComplete="off"
                    type="password"
                    onChange={handleChange}
                    name="confirm_new_password"
                    class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                  />
                </div>
              </div>
              <button
                disabled={isLoading}
                type="submit"
                class="w-full px-4 py-2 bg-primary text-white font-semibold rounded-md shadow-sm"
              >
                {isLoading ? (
                  <div class="flex justify-center items-center">
                    <div class="w-5 h-5 rounded-full border-4 border-gray-400 border-t-white animate-spin"></div>
                  </div>
                ) : (
                  <span>Update Password</span>
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminProfile;
