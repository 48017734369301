import React, { useState } from "react";
import { API } from "../../../API";
import { toast } from "react-toastify";
import DeleteConfirmation from "../../models/DeleteConfirmation";
import CheckboxComponent from "../../button/CheckboxComponent ";

const BannersSubAdminTable = ({ data, reload }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState({});
  const image_url = process.env.REACT_APP_IMAGE_URL;

  const deleteBanner = async (id) => {
    try {
      const { data } = await API.delete(`/banner/delete/${id}`);
      if (data.success) {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
      setShowConfirmation(false);
      reload();
      setSelectedBanner({});
    } catch (error) {
      toast.error(error.response.data.message);
      console.log("error in delete banner:", error);
    }
  };
  return (
    <div>
      <div className="rounded-t text-white flex items-center justify-center mt-4 px-6 py-2 font-bold text-xl border-b border-gray-100 bg-primary">
        Banners List
      </div>
      <div className="overflow-x-auto overflow-y-auto relative h-[85vh]">
        <table className="border-collapse table-auto table-striped relative min-w-full divide-y divide-gray-200">
          <thead className=" ">
            <tr className="sticky top-0 bg-gray-100 text-base font-bold text-gray-500 uppercase z-10">
              <th scope="col" className="px-6 py-3 text-left tracking-wider">
                No
              </th>
              <th scope="col" className="px-6 py-3 text-left tracking-wider">
                Image
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left min-w-40  tracking-wider"
              >
                Branch Name
              </th>
              <th scope="col" className="px-6 py-3 text-left tracking-wider">
                Show
              </th>
              <th scope="col" className="px-6 py-3 text-left tracking-wider">
                Delete
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {data?.map((branch, i) => (
              <tr key={i} className="text-lg text-gray-900">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div>{i + 1}</div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <img className="h-10" src={image_url + branch?.image} />
                  </div>
                </td>
                <td className="px-6 py-4 min-w-60 ">
                  <ul className="list-disc">
                    {branch?.branches?.map((b) => (
                      <li>
                        <input
                          className={`w-4 h-4 rounded border-gray-300 ${
                            b?.status
                              ? "bg-red-600 border-red-600"
                              : "bg-gray-100 border-gray-300"
                          }`}
                          style={{
                            opacity: b?.status ? 1 : 0.5,
                          }}
                          type="checkbox"
                          checked={b?.status}
                        />{" "}
                        {b?.branchId?.name}
                      </li>
                    ))}
                  </ul>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <CheckboxComponent
                    key={branch?._id}
                    initialStatus={branch?.branches[0]?.status}
                    id={branch?._id}
                    reload={reload}
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span
                    onClick={() => {
                      setSelectedBanner(branch);
                      setShowConfirmation(true);
                    }}
                    className="cursor-pointer inline-flex leading-5 text-white  bg-primary hover:bg-red-700  font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                  >
                    Delete
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showConfirmation && (
        <DeleteConfirmation
          msg={`Are you sure you want to delete ${selectedBanner?.branchId?.name} branch banner ?`}
          close={setShowConfirmation}
          task={() => deleteBanner(selectedBanner?._id)}
        />
      )}

      {/* <EditBranchModel
        key={selectedBranch?.name}
        show={editPopupShow}
        close={setEditPopupShow}
        data={selectedBranch}
        reload={reload}
      /> */}
    </div>
  );
};

export default BannersSubAdminTable;
