import React, { useEffect, useState } from "react";
import { API } from "../API";
import { toast } from "react-toastify";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const { auth, setAuth, setUserRole } = useAuth();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [logindata, setLoginData] = useState({
    userName: "",
    password: "",
  });

  useEffect(() => {
    if (auth?.user) {
      navigate("/admin-dashboard");
    }
  }, [auth?.user, navigate]);

  const inputEvent = (e) => {
    const { name, value } = e.target;
    setLoginData((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };
  const hendlelLogin = async (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      setSubmitting(true);
      const res = await API.post(`/user/login`, logindata);
      if (res.data.success) {
        localStorage.setItem("authToken", res?.data?.data?.accessToken);
        localStorage.setItem("user", JSON.stringify(res?.data?.data?.user));
        const user = res?.data?.data?.user;
        const role = res?.data?.data?.user?.role;
        const accessToken = res?.data?.data?.accessToken;
        setUserRole([role]);
        setAuth({ user: user, role: [role], accessToken: accessToken });
        toast.success(res?.data?.message);

        const path =
          role === "SUPER_ADMIN"
            ? "/admin-dashboard"
            : role === "RECEPTIONIST"
            ? "/receptionist-dashboard"
            : role === "COUNSELOR"
            ? "/counselor-dashboard"
            : "/unauthorized";
        navigate(path);
      } else {
        toast.error(res?.data?.message);
      }
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      toast.error(error?.response?.data?.message);
      console.log("error in hendlelLogin: ", error);
    }
  };
  return (
    <div>
      <div className="fixed inset-0 z-50 flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="bg-white backdrop-blur-xl rounded-lg py-10 w-[25%] shadow-xl">
          <div className="flex items-center justify-center my-6 text-2xl font-semibold text-gray-900">
            <img className="h-full w-[60%]" src="/images/Logo.png" alt="logo" />
          </div>
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
              Sign in to your account
            </h1>
            <form className="space-y-4 md:space-y-6" onSubmit={hendlelLogin}>
              <div>
                <label
                  for="username"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Username
                </label>
                <input
                  onChange={inputEvent}
                  type="text"
                  name="userName"
                  id="userName"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Enter your username"
                  required=""
                />
              </div>
              <div>
                <label
                  for="password"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Password
                </label>
                <div className="relative">
                  <input
                    onChange={inputEvent}
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    required=""
                  />
                  <span class="absolute inset-y-0 right-0 flex items-center pr-3">
                    {showPassword ? (
                      <svg
                        className="w-5 h-5 cursor-pointer"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 36 22"
                        fill="none"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <path
                          d="M18 0C11.1218 0 4.88432 3.76312 0.281681 9.87542C-0.0938938 10.3762 -0.0938938 11.0758 0.281681 11.5766C4.88432 17.6962 11.1218 21.4593 18 21.4593C24.8782 21.4593 31.1157 17.6962 35.7183 11.5839C36.0939 11.0832 36.0939 10.3836 35.7183 9.88279C31.1157 3.76312 24.8782 0 18 0ZM18.4934 18.2854C13.9276 18.5726 10.1571 14.8094 10.4443 10.2363C10.68 6.46579 13.7361 3.40963 17.5066 3.17398C22.0724 2.88677 25.8429 6.64989 25.5557 11.2231C25.3127 14.9862 22.2565 18.0423 18.4934 18.2854ZM18.2651 14.7947C15.8055 14.9494 13.7729 12.9242 13.935 10.4646C14.0601 8.43203 15.7097 6.78981 17.7423 6.65726C20.2019 6.50261 22.2344 8.52777 22.0724 10.9874C21.9399 13.0273 20.2903 14.6695 18.2651 14.7947Z"
                          fill="#AABBC6"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="w-5 h-5 cursor-pointer"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 50 50"
                        fill="none"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <path
                          d="M24.6459 18.75L31.25 25.3333V25C31.25 23.3424 30.5916 21.7527 29.4195 20.5806C28.2474 19.4085 26.6576 18.75 25 18.75H24.6459ZM15.6875 20.4167L18.9167 23.6458C18.8125 24.0833 18.75 24.5208 18.75 25C18.75 26.6576 19.4085 28.2473 20.5806 29.4194C21.7527 30.5915 23.3424 31.25 25 31.25C25.4584 31.25 25.9167 31.1875 26.3542 31.0833L29.5834 34.3125C28.1875 35 26.6459 35.4167 25 35.4167C22.2374 35.4167 19.5878 34.3192 17.6343 32.3657C15.6808 30.4122 14.5834 27.7627 14.5834 25C14.5834 23.3542 15 21.8125 15.6875 20.4167ZM4.16671 8.89583L8.91671 13.6458L9.85421 14.5833C6.41671 17.2917 3.70837 20.8333 2.08337 25C5.68754 34.1458 14.5834 40.625 25 40.625C28.2292 40.625 31.3125 40 34.125 38.875L35.0209 39.75L41.1042 45.8333L43.75 43.1875L6.81254 6.25M25 14.5833C27.7627 14.5833 30.4122 15.6808 32.3657 17.6343C34.3192 19.5878 35.4167 22.2373 35.4167 25C35.4167 26.3333 35.1459 27.625 34.6667 28.7917L40.7709 34.8958C43.8959 32.2917 46.3959 28.875 47.9167 25C44.3125 15.8542 35.4167 9.375 25 9.375C22.0834 9.375 19.2917 9.89583 16.6667 10.8333L21.1875 15.3125C22.375 14.8542 23.6459 14.5833 25 14.5833Z"
                          fill="#AABBC6"
                        />
                      </svg>
                    )}
                  </span>
                </div>
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="w-[40%] text-white font-bold bg-primary hover:bg-primary rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  {submitting ? (
                    <div class="flex justify-center items-center">
                      <div class="w-5 h-5 rounded-full border-4 border-gray-400 border-t-white animate-spin"></div>
                    </div>
                  ) : (
                    "Sign in"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        className="opacity-70 fixed inset-0 z-40 bg-no-repeat bg-bottom bg-cover blur-sm"
        style={{
          backgroundImage: `url('/images/login/login_bg.jpeg')`,
        }}
      ></div>
    </div>
  );
};

export default Login;
