import React, { useEffect, useState } from "react";
import BannersTable from "../tables/banners/BannersTable";
import { FaPlus } from "react-icons/fa";
import { API } from "../../API";
import AddBannerModel from "../models/banner/AddBannerModel";
import useAuth from "../../hooks/useAuth";
import AddBannerSubAdminModel from "../models/banner/AddBannerSubAdminModel";
import BannersSubAdminTable from "../tables/banners/BannersSubAdminTable";

const SubAdminBanners = () => {
  const { auth } = useAuth();
  const [addBannerPopupShow, setAddBannerPopupShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [banners, setBanners] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");

  useEffect(() => {
    if (auth?.user?.branchId) {
      setSelectedBranch(auth?.user?.branchId);
    }
  }, [auth]);

  const getAllBanners = async () => {
    try {
      setIsLoading(true);
      const { data } = await API.get(`/banner/get/${selectedBranch}`);
      setBanners(data.data.banner);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error in getAllBranch :", error);
    }
  };

  useEffect(() => {
    if (selectedBranch) {
      getAllBanners();
    }
  }, [selectedBranch]);

  return (
    <div className="p-4">
      <div className="flex justify-end items-center">
        <div
          onClick={() => setAddBannerPopupShow(true)}
          class="flex items-center text-white gap-2 bg-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center cursor-pointer"
          href={"/product/add"}
        >
          <FaPlus /> Add Banner
        </div>
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center h-[50vh]">
          <div class="w-16 h-16 border-8 border-dashed rounded-full animate-spin border-primary"></div>
        </div>
      ) : banners.length > 0 ? (
        <BannersSubAdminTable data={banners} reload={getAllBanners} />
      ) : (
        <div className="flex justify-center items-center h-[50vh]">
          <div class="text-xl">Data Not Found.</div>
        </div>
      )}
      {addBannerPopupShow && (
        <AddBannerSubAdminModel
          selectedBranch={selectedBranch}
          reload={getAllBanners}
          show={addBannerPopupShow}
          close={setAddBannerPopupShow}
        />
      )}
    </div>
  );
};

export default SubAdminBanners;
