import React from "react";
import { TfiAlert } from "react-icons/tfi";

const DeleteConfirmation = ({ msg, close, task }) => {
  return (
    <div>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto  fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="w-72 sm:w-96 xl:w-1/3 my-6 mx-auto max-w-2xl relative bg-white  rounded-2xl flex flex-col">
          <div className="bg-white w-full rounded-lg  shadow-lg  max-h-[80vh] overflow-y-auto relative">
            <div class="items-center flex justify-between px-2 font-bold text-center text-lg md:text-2xl  bg-primary sticky top-0 m-3 rounded">
              <div className="flex gap-2 text-white items-center">
                <TfiAlert /> <span>Delete Confirmation</span>
              </div>
              <button
                type="button"
                class=" rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black"
                onClick={() => close(false)}
              >
                <svg
                  class="h-6 w-6"
                  style={{ color: "black" }}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="white"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="flex justify-center items-center ">
              <div className="p-4 text-center text-xl w-full ">
                <p>{msg}</p>
                <div className="mt-4">
                  <button
                    className="bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded"
                    onClick={() => task()}
                  >
                    Delete
                  </button>
                  <button
                    className="ml-3 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                    onClick={() => close(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-70 fixed inset-0 z-40 bg-black"></div>
    </div>
  );
};

export default DeleteConfirmation;
