import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { API } from "../../API";
import { FaPlus } from "react-icons/fa";
import CountryTable from "../tables/country/CountryTable";
import AddCountryModel from "../models/country/AddCountryModel";
import AddCountryModelSubAdmin from "../models/country/AddCountryModelSubAdmin";
import CountryTableSubAdmin from "../tables/country/CountryTableSubAdmin";

const SubAdminCountry = () => {
  const { auth } = useAuth();
  const [addCountryPopupShow, setAddCountryPopupShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [filterArray, setFilterArray] = useState([]);

  useEffect(() => {
    if (auth?.user?.branchId) {
      setSelectedBranch(auth?.user?.branchId);
    }
  }, [auth]);

  const getAllCountry = async () => {
    try {
      setIsLoading(true);
      const { data } = await API.get(`/country/by-branch/${selectedBranch}`);
      setFilterArray(data.data.countries);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error in getAllCountry :", error);
    }
  };

  useEffect(() => {
    getAllCountry();
  }, [selectedBranch]);
  return (
    <div className="p-4">
      <div className="flex justify-end items-center">
        <div
          onClick={() => setAddCountryPopupShow(true)}
          class="flex items-center text-white gap-2 bg-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center cursor-pointer"
          href={"/product/add"}
        >
          <FaPlus /> Add Country
        </div>
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center h-[50vh]">
          <div class="w-16 h-16 border-8 border-dashed rounded-full animate-spin border-primary"></div>
        </div>
      ) : filterArray.length > 0 ? (
        <CountryTableSubAdmin data={filterArray} reload={getAllCountry} />
      ) : (
        <div className="flex justify-center items-center h-[50vh]">
          <div class="text-xl">Data Not Found.</div>
        </div>
      )}
      <AddCountryModelSubAdmin
        show={addCountryPopupShow}
        close={setAddCountryPopupShow}
        reload={getAllCountry}
      />
    </div>
  );
};

export default SubAdminCountry;
