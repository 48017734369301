import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { API } from "../../API";
import { FaPlus } from "react-icons/fa";
import CourseTableSubAdmin from "../tables/course/CourseTableSubAdmin";
import AddCourseModelSubAdmin from "../models/course/AddCourseModelSubAdmin";

const SubAdminCourse = () => {
  const { auth } = useAuth();
  const [addCoursePopupShow, setAddCoursePopupShow] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [filterArray, setFilterArray] = useState([]);

  useEffect(() => {
    if (auth?.user?.branchId) {
      setSelectedBranch(auth?.user?.branchId);
    }
  }, [auth]);

  const getAllCourse = async () => {
    try {
      setIsLoading(true);
      const { data } = await API.get(`/course/get/${selectedBranch}`);
      setFilterArray(data.data.course);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error in getAllCourse :", error);
    }
  };

  useEffect(() => {
    getAllCourse();
  }, [selectedBranch]);

  return (
    <div className="p-4">
      <div className="flex justify-end items-center">
        <div
          onClick={() => setAddCoursePopupShow(true)}
          class="flex items-center text-white gap-2 bg-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center cursor-pointer"
        >
          <FaPlus /> Add Course
        </div>
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center h-[50vh]">
          <div class="w-16 h-16 border-8 border-dashed rounded-full animate-spin border-primary"></div>
        </div>
      ) : filterArray.length > 0 ? (
        <CourseTableSubAdmin data={filterArray} reload={getAllCourse} branchId={selectedBranch} />
      ) : (
        <div className="flex justify-center items-center h-[50vh]">
          <div class="text-xl">Data Not Found.</div>
        </div>
      )}
      <AddCourseModelSubAdmin
        show={addCoursePopupShow}
        close={setAddCoursePopupShow}
        branchId={selectedBranch}
        reload={getAllCourse}
      />
    </div>
  );
};

export default SubAdminCourse;
