/* eslint-disable jsx-a11y/anchor-is-valid */
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import useAuth from "../hooks/useAuth";
import { TfiMenuAlt } from "react-icons/tfi";
import { IoMdLogOut } from "react-icons/io";
import DropdownUser from "./card/DropdownUser";

export const Header = ({ isOpen, setIsOpen }) => {
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("user");
    setAuth({});
    toast.success("logout successfully.");
    navigate("/login");
  };
  return (
    <>
      <header className="header sticky top-0 bg-white shadow-md flex items-center justify-between px-4 py-2">
        <div>
          {/* <div
            className="cursor-pointer text-white bg-primary p-2 rounded"
            onClick={() => setIsOpen(!isOpen)}
          >
            <TfiMenuAlt className={`${isOpen ? "" : "rotate-180"} h-7 w-7`} />
          </div> */}
        </div>

        {/* <div>
          <div
            className="cursor-pointer hover:text-white bg-gray-200 hover:bg-primary p-2 rounded"
            onClick={() => logout()}
          >
            <IoMdLogOut className={`h-7 w-7`} />
          </div>
        </div> */}

        <DropdownUser />
      </header>
    </>
  );
};
