import React, { useEffect, useState } from "react";
import { API } from "../../../API";
import { toast } from "react-toastify";
import moment from "moment";

const ViewCounselorModel = ({ counselor, show, close }) => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  const getAllBranch = async () => {
    try {
      setIsLoading(true)
      const { data } = await API.get(`/session/by-counselor/${counselor?._id}`);
      setUsers(data.data.sessions);
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log("error in getAllBranch :", error);
    }
  };

  useEffect(() => {
    getAllBranch();
  }, []);

  return (
    <div className={`${show ? "" : "hidden"}`}>
      <div class="justify-center items-center flex overflow-x-hidden overflow-y-auto  fixed inset-0 z-50 outline-none focus:outline-none">
        <div class="w-72 sm:w-96 xl:w-4/5 my-6 mx-auto  relative bg-white  rounded-2xl flex flex-col">
          <div class="bg-white w-full rounded-lg  shadow-lg min-h-[70vh] max-h-[80vh] overflow-y-auto relative">
            <div class="items-center flex justify-between flex-wrap md:flex-nowrap py-2 md:py-0 px-2 font-bold text-center text-lg md:text-2xl  bg-primary sticky top-0 m-3 rounded">
              <h2 class="grow text-lg lg:text-2xl font-bold text-white  text-center">
                <span class="bg-gradient-to-r  bg-clip-text">
                  {counselor?.name} Counselor Sessions
                </span>
              </h2>
              <button
                type="button"
                onClick={() => close(false)}
                class=" rounded-md md:p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black"
              >
                <svg
                  class="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                  style={{ color: "white" }}
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            {isLoading ? (
              <div className="flex justify-center items-center h-[50vh]">
                <div class="w-16 h-16 border-8 border-dashed rounded-full animate-spin border-primary"></div>
              </div>
            ) : (
              <div className="p-4">
                <div className="overflow-x-auto overflow-y-auto relative h-full">
                  <table className="border-collapse table-auto table-striped relative min-w-full divide-y divide-gray-200">
                    <thead className=" ">
                      <tr className="sticky top-0 bg-gray-100 text-base font-bold text-gray-500 uppercase">
                        <th
                          scope="col"
                          className="px-6 py-3 text-left tracking-wider sticky left-0 bg-gray-100"
                        >
                          No
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left tracking-wider"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left min-w-40  tracking-wider"
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left min-w-40  tracking-wider"
                        >
                          Start Time
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left min-w-40  tracking-wider"
                        >
                          End Time
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left min-w-40  tracking-wider"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left min-w-40  tracking-wider"
                        >
                          Reason/Review
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left min-w-40  tracking-wider"
                        >
                          Branch
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {users &&
                        users?.map((u, i) => (
                          <tr key={i} className="text-lg text-gray-900">
                            <td className="px-6 py-4 whitespace-nowrap sticky left-0 bg-white">
                              <div className="flex items-center">
                                <div>{i + 1}</div>
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="flex items-center">
                                <div>{u?.studentId?.name}</div>
                              </div>
                            </td>
                            <td className="px-6 py-4 min-w-60">
                              <div>
                                {moment(u?.startTime).format("DD-MM-YYYY")}
                              </div>
                            </td>
                            <td className="px-6 py-4 min-w-60">
                              <div>
                                {moment(u?.startTime).format("hh:mm A")}
                              </div>
                            </td>
                            <td className="px-6 py-4 min-w-60">
                              <div>{moment(u?.endTime).format("hh:mm A")}</div>
                            </td>
                            <td className="px-6 py-4 min-w-60">
                              <div>{u?.status}</div>
                            </td>
                            <td className="px-6 py-4 min-w-60">
                              <div>
                                {u?.status === "CANCELLED"
                                  ? u?.reason
                                  : u?.status === "COMPLETED"
                                  ? u?.review
                                  : "-"}
                              </div>
                            </td>
                            <td className="px-6 py-4 min-w-60">
                              <div>{u?.branchId?.name}</div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div class="opacity-70 fixed inset-0 z-40 bg-black"></div>
    </div>
  );
};

export default ViewCounselorModel;
