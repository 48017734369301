import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API } from "../../API";
import { FaCodeBranch } from "react-icons/fa";
import { GrGroup } from "react-icons/gr";
import { LiaChalkboardTeacherSolid } from "react-icons/lia";
import { PiFrameCorners, PiStudentFill } from "react-icons/pi";
import { GiTeacher } from "react-icons/gi";
import { BsBook } from "react-icons/bs";

const SuperAdminDashbord = () => {
  const [dashboardData, setDashboardData] = useState();
  const array = [
    {
      name: "branches",
      link: "/admin-branch",
      icon: <FaCodeBranch className="h-9 w-9 text-white" />,
    },
    {
      name: "receptionists",
      link: "/admin-receptionist",
      icon: <GrGroup className="h-9 w-9 text-white" />,
    },
    {
      name: "counselors",
      link: "/admin-counselors",
      icon: <LiaChalkboardTeacherSolid className="h-12 w-12 text-white" />,
    },
    {
      name: "tutors",
      link: "/admin-tutors",
      icon: <GiTeacher className="h-10 w-10 text-white" />,
    },
    {
      name: "students",
      link: "/admin-students",
      icon: <PiStudentFill className="h-12 w-12 text-white" />,
    },
    {
      name: "banners",
      link: "/admin-banners",
      icon: <PiFrameCorners className="h-12 w-12 text-white" />,
    },
    // {
    //   name: "course",
    //   link: "/admin-course",
    //   icon: <BsBook className="h-10 w-10 text-white" />,
    // },
  ];

  const getDashboardData = async () => {
    try {
      const res = await API.get("/user/super-dashboard");
      if (res.data.success) {
        setDashboardData(res.data.data);
      }
    } catch (error) {
      console.log("error in getDashboardData :",error);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <div className="p-6">
      {dashboardData ? (
        <div class="grid grid-cols-1 md:grid-cols-5 lg:grid-rows-5 gap-6 mb-6">
          {dashboardData &&
            array.map((item) => (
              <Link
                to={item.link}
                class="bg-white rounded-xl  p-6 shadow-xl hover:shadow-md shadow-black/5 hover:shadow-red-300"
              >
                <div class="flex justify-between my-3">
                  <div className="flex justify-between w-full">
                    <div>
                      <div class="text-3xl font-semibold mb-1">
                        {dashboardData[`${item.name}`] ? dashboardData[`${item.name}`] : 0}
                      </div>
                      <div class="text-lg font-medium capitalize">
                        {item.name === "receptionists"
                          ? "branch admin"
                          : item.name}
                      </div>
                    </div>
                    <div class="flex h-20 w-20 items-center justify-center rounded-full bg-primary">
                      {item?.icon}
                    </div>
                  </div>
                </div>
              </Link>
            ))}
        </div>
      ) : (
        <div className="flex justify-center items-center h-[50vh]">
          <div class="w-16 h-16 border-8 border-dashed rounded-full animate-spin border-primary"></div>
        </div>
      )}
    </div>
  );
};

export default SuperAdminDashbord;
