import React, { useEffect, useState } from "react";
import { API } from "../../../API";
import { toast } from "react-toastify";

const AddTutorModel = ({ show, close, reload }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [branch, setBranch] = useState([]);
  const [course, setCourse] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNo: "",
    branchId: "",
    courseId: "",
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const getAllBranch = async () => {
    try {
      const { data } = await API.get("/branch/get");
      setBranch(data.data.branch);
      setFormData({ ...formData, branchId: data.data.branch[0]._id });
    } catch (error) {
      console.log("error in getAllBranch :", error);
    }
  };

  useEffect(() => {
    getAllBranch();
  }, []);

  const getAllCourse = async (id) => {
    try {
      if (id) {
        const { data } = await API.get(`/course/get/${id}`);
        setCourse(data.data.course);
        setFormData({ ...formData, courseId: data.data.course[0]?._id });
      }
    } catch (error) {
      console.log("error in getAllCourse :", error);
    }
  };

  useEffect(() => {
    getAllCourse(formData.branchId ? formData.branchId : branch[0]?._id);
  }, [formData.branchId, branch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const { data } = await API.post("/user/add-tutor", formData);
      if (data.success) {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
      setFormData({
        name: "",
        email: "",
        phoneNo: "",
        branchId: "",
        courseId: "",
      });
      reload();
      close(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message);
      console.log("error in add tutor", error);
    }
  };

  return (
    <div className={`${show ? "" : "hidden"}`}>
      <div class="justify-center items-center flex overflow-x-hidden overflow-y-auto  fixed inset-0 z-50 outline-none focus:outline-none">
        <div class="w-72 sm:w-96 xl:w-1/3 my-6 mx-auto max-w-2xl relative bg-white  rounded-2xl flex flex-col">
          <div class="bg-white w-full rounded-lg  shadow-lg  max-h-[80vh] overflow-y-auto relative">
            <div class="items-center flex justify-between flex-wrap md:flex-nowrap py-2 md:py-0 px-2 font-bold text-center text-lg md:text-2xl  bg-primary sticky top-0 m-3 rounded">
              <h2 class="grow text-lg lg:text-2xl font-bold text-white  text-center">
                <span class="bg-gradient-to-r  bg-clip-text">Add Tutor</span>
              </h2>
              <button
                type="button"
                onClick={() => close(false)}
                class=" rounded-md md:p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black"
              >
                <svg
                  class="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                  style={{ color: "white" }}
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            <form
              class="flex justify-center items-center"
              onSubmit={handleSubmit}
            >
              <div class="space-y-2 px-4 pt-2 pb-4 w-full ">
                <div>
                  <label
                    for="name"
                    class="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Tutor Name
                  </label>
                  <input
                    onChange={handleChange}
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="off"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                    placeholder="Tutor name"
                  />
                </div>
                <div>
                  <label
                    for="email"
                    class="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Tutor Email
                  </label>
                  <input
                    onChange={handleChange}
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="off"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                    placeholder="Tutor email"
                  />
                </div>
                <div>
                  <label
                    for="phoneNo"
                    class="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Tutor Phone Number
                  </label>
                  <input
                    onChange={handleChange}
                    type="number"
                    name="phoneNo"
                    id="phoneNo"
                    autoComplete="off"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                    placeholder="Tutor phone number"
                  />
                </div>
                <div class="col-span-2">
                  <label
                    for="address"
                    class="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Branch name
                  </label>
                  <select
                    onChange={handleChange}
                    name="branchId"
                    id="branchId"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  >
                    {branch &&
                      branch?.map((c, i) => (
                        <option key={i} value={c?._id}>
                          {c?.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div class="col-span-2">
                  <label
                    for="address"
                    class="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Course
                  </label>
                  <select
                    onChange={handleChange}
                    name="courseId"
                    id="courseId"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  >
                    {course &&
                      course?.map((c, i) => (
                        <option key={i} value={c?._id}>
                          {c?.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div class="flex items-center justify-center">
                  <button
                    type="submit"
                    disabled={isLoading}
                    class="bg-primary  text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline w-fit"
                  >
                    {isLoading ? (
                      <div class="flex justify-center items-center">
                        <div class="w-5 h-5 rounded-full border-4 border-gray-400 border-t-white animate-spin"></div>
                      </div>
                    ) : (
                      <span>Save Tutor</span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="opacity-70 fixed inset-0 z-40 bg-black"></div>
    </div>
  );
};

export default AddTutorModel;
