import React, { useState } from "react";
import { API } from "../../../API";
import { toast } from "react-toastify";

const AddBranchModel = ({ show, close, reload }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    locationLink: "",
    address: "",
    gstNo:""
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const { data } = await API.post("/branch/add", formData);
      if (data.success) {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
      setFormData({
        name: "",
        locationLink: "",
        address: "",
        gstNo:""
      });
      reload();
      close(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message);
      console.log("error in add branch", error);
    }
  };

  return (
    <div className={`${show ? "" : "hidden"}`}>
      <div class="justify-center items-center flex overflow-x-hidden overflow-y-auto  fixed inset-0 z-50 outline-none focus:outline-none">
        <div class="w-72 sm:w-96 xl:w-1/3 my-6 mx-auto max-w-2xl relative bg-white  rounded-2xl flex flex-col">
          <div class="bg-white w-full rounded-lg  shadow-lg  max-h-[80vh] overflow-y-auto relative">
            <div class="items-center flex justify-between flex-wrap md:flex-nowrap py-2 md:py-0 px-2 font-bold text-center text-lg md:text-2xl  bg-primary sticky top-0 m-3 rounded">
              <h2 class="grow text-lg lg:text-2xl font-bold text-white  text-center">
                <span class="bg-gradient-to-r  bg-clip-text">Add Branch</span>
              </h2>
              <button
                type="button"
                onClick={() => close(false)}
                class=" rounded-md md:p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black"
              >
                <svg
                  class="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                  style={{ color: "white" }}
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            <form
              class="flex justify-center items-center"
              onSubmit={handleSubmit}
            >
              <div class="space-y-2 px-4 pt-2 pb-4 w-full ">
                <div>
                  <label
                    for="name"
                    class="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Branch Name
                  </label>
                  <input
                    onChange={handleChange}
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="off"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                    placeholder="Branch name"
                  />
                </div>
                <div>
                  <label
                    for="locationLink"
                    class="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Branch location link
                  </label>
                  <input
                    onChange={handleChange}
                    type="text"
                    name="locationLink"
                    id="locationLink"
                    autoComplete="off"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                    placeholder="Branch location link"
                  />
                </div>
                <div>
                  <label
                    for="gstNo"
                    class="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Branch GST No
                  </label>
                  <input
                    onChange={handleChange}
                    type="text"
                    name="gstNo"
                    id="gstNo"
                    autoComplete="off"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                    placeholder="Branch GST number"
                  />
                </div>
                <div class="col-span-2">
                  <label
                    for="address"
                    class="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Branch address
                  </label>
                  <textarea
                    onChange={handleChange}
                    id="address"
                    name="address"
                    rows="4"
                    class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
                    placeholder="Write branch address here"
                  ></textarea>
                </div>
                <div class="flex items-center justify-center">
                  <button
                    disabled={isLoading}
                    type="submit"
                    class="bg-gradient-to-r bg-primary  text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline w-fit"
                  >
                    {isLoading ? (
                      <div class="flex justify-center items-center">
                        <div class="w-5 h-5 rounded-full border-4 border-gray-400 border-t-white animate-spin"></div>
                      </div>
                    ) : (
                      <span>Save Branch</span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="opacity-70 fixed inset-0 z-40 bg-black"></div>
    </div>
  );
};

export default AddBranchModel;
