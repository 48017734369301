import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { API } from "../../API";
import CounselorTable from "../tables/counselor/CounselorTable";
import useAuth from "../../hooks/useAuth";
import AddCounselorSubAdminModel from "../models/counselor/AddCounselorSubAdminModel";
import CounselorTableSubAdmin from "../tables/counselor/CounselorTableSubAdmin";
import { MdDownload } from "react-icons/md";
import moment from "moment";

const SubAdminCounselors = () => {
  const { auth } = useAuth();
  const [counselors, setCounselors] = useState([]);
  const [filterArray, setFilterArray] = useState([]);
  const [addCounselorPopupShow, setAddCounselorPopupShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState("");

  useEffect(() => {
    if (auth?.user?.branchId) {
      setSelectedBranch(auth?.user?.branchId);
    }
  }, [auth]);

  const getAllCounselors = async () => {
    try {
      setIsLoading(true);
      const { data } = await API.get(
        `/user/all?role=COUNSELOR&branchId=${auth?.user?.branchId}`
      );
      setCounselors(data.data);
      setFilterArray(data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error in getAllCounselors :", error);
    }
  };

  useEffect(() => {
    if (selectedBranch) {
      getAllCounselors(selectedBranch);
    }
  }, [selectedBranch]);

  const handleSearch = (val) => {
    if (!val) {
      setFilterArray(counselors);
    } else {
      const data = counselors?.filter((b) =>
        b?.name?.toLowerCase()?.includes(val?.toLowerCase())
      );
      setFilterArray(data);
    }
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center">
        <div className="w-[25%]">
          <div class="relative flex items-center w-full h-10 rounded-lg focus-within:shadow-lg bg-white focus-within:border-2  focus-within:border-primary border-2 overflow-hidden">
            <div class="grid place-items-center h-full w-12 text-gray-300">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>

            <input
              class="peer h-full w-full outline-none text-base text-gray-700 pr-2"
              type="text"
              id="search"
              autoComplete="off"
              onChange={(e) => handleSearch(e.target.value)}
              placeholder="Search something.."
            />
          </div>
        </div>

        <div
          onClick={() => setAddCounselorPopupShow(true)}
          class="flex items-center text-white gap-2 bg-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center cursor-pointer"
        >
          <FaPlus /> Add Counselor
        </div>
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center h-[50vh]">
          <div class="w-16 h-16 border-8 border-dashed rounded-full animate-spin border-primary"></div>
        </div>
      ) : filterArray.length > 0 ? (
        <CounselorTableSubAdmin data={filterArray} reload={getAllCounselors} />
      ) : (
        <div className="flex justify-center items-center h-[50vh]">
          <div class="text-xl">Data Not Found.</div>
        </div>
      )}

      {addCounselorPopupShow && (
        <AddCounselorSubAdminModel
          reload={getAllCounselors}
          show={addCounselorPopupShow}
          close={setAddCounselorPopupShow}
          branchId={auth?.user?.branchId}
        />
      )}
    </div>
  );
};

export default SubAdminCounselors;
