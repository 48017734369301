import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { API } from "../API";

const RequiredAuth = ({ allowedRoles }) => {
  const { auth, setAuth, userRole } = useAuth();
  const location = useLocation();

  const [isLoggedInUser, setIsLoggedInUser] = useState({});
  const authToken = localStorage.getItem("authToken");
  const user = JSON.parse(localStorage.getItem("user"));
  const [pathName, setPathName] = useState("");
  const [callSuccess, setCallSuccess] = useState(false);

  async function getUser() {
    // const { data } = await API.get(`/user/me`);\
    setIsLoggedInUser(user);

    setAuth({
      ...auth,
      user: user,
      accessToken: authToken,
      role: [user.role],
    });

    setPathName(
      user.role === "SUPER_ADMIN"
        ? "/admin-dashboard"
        : user.role === "RECEPTIONIST"
        ? "/receptionist-dashboard"
        : "/user-dashboard"
    );
  }

  useEffect(() => {
    if (user) {
      getUser();
    }
  }, []);

  // if (callSuccess) {
  return allowedRoles?.includes(user?.role) ? (
    <Outlet />
  ) : auth?.user ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
  // }
};

export default RequiredAuth;
