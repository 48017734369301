import React, { useEffect, useState } from "react";
import { API } from "../../../API";
import { toast } from "react-toastify";

const AddSessionsModel = ({ show, close, reload }) => {
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState({});
  const [types, setTypes] = useState(["OFFLINE", "ONLINE"]);
  const [formData, setFormData] = useState({
    startTime: "",
    endTime: "",
    counselorId: "",
    studentId: "",
    countryId: "",
    review: "",
    type: "OFFLINE",
  });

  const getStudents = async () => {
    try {
      const { data } = await API.get("/user/new-student-by-counselor");
      setStudents(data.data.students);
      const localCounselor = JSON.parse(localStorage.getItem("user"));
      if (localCounselor) {
        setFormData({
          ...formData,
          studentId: data.data.students[0]?._id,
          counselorId: localCounselor?._id,
          countryId: localCounselor?.country,
        });
      }
    } catch (error) {
      console.log("error in getStudents :", error);
    }
  };

  useEffect(() => {
    getStudents();
  }, []);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (formData.studentId) {
      const data = students?.find(
        (student) => student?._id === formData?.studentId
      );
      setSelectedStudent(data);
    }
  }, [formData.studentId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await API.post("/session/add-manual", formData);
      if (data.success) {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
      setFormData({
        startTime: "",
        endTime: "",
        counselorId: "",
        studentId: "",
        countryId: "",
        review: "",
        type: "OFFLINE",
      });
      reload();
      close(false);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log("error in add counselor", error);
    }
  };

  return (
    <div className={`${show ? "" : "hidden"}`}>
      <div class="justify-center items-center flex overflow-x-hidden overflow-y-auto  fixed inset-0 z-50 outline-none focus:outline-none">
        <div class="w-72 sm:w-96 xl:w-1/3 my-6 mx-auto max-w-2xl relative bg-white  rounded-2xl flex flex-col">
          <div class="bg-white w-full rounded-lg  shadow-lg  max-h-[80vh] overflow-y-auto relative">
            <div class="items-center flex justify-between flex-wrap md:flex-nowrap py-2 md:py-0 px-2 font-bold text-center text-lg md:text-2xl  bg-primary sticky top-0 m-3 rounded">
              <h2 class="grow text-lg lg:text-2xl font-bold text-white  text-center">
                <span class="bg-gradient-to-r  bg-clip-text">Add Sessions</span>
              </h2>
              <button
                type="button"
                onClick={() => close(false)}
                class=" rounded-md md:p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black"
              >
                <svg
                  class="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                  style={{ color: "white" }}
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            <form
              class="flex justify-center items-center"
              onSubmit={handleSubmit}
            >
              <div class="space-y-2 px-4 pt-2 pb-4 w-full ">
                <div>
                  <label
                    for="name"
                    class="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Student Name : {selectedStudent?.userName}
                  </label>
                  <select
                    onChange={handleChange}
                    name="studentId"
                    id="studentId"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  >
                    {students &&
                      students?.map((c, i) => (
                        <option key={i} value={c?._id}>
                          {c?.name}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="flex justify-center items-center gap-4">
                  <div className="w-full">
                    <label
                      for="startTime"
                      class="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      Start Time
                    </label>
                    <input
                      onChange={handleChange}
                      type="datetime-local"
                      name="startTime"
                      id="startTime"
                      autoComplete="off"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                      placeholder="Counselor email"
                    />
                  </div>
                  <div className="w-full">
                    <label
                      for="endTime"
                      class="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      End Time
                    </label>
                    <input
                      onChange={handleChange}
                      type="datetime-local"
                      name="endTime"
                      id="endTime"
                      autoComplete="off"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                      placeholder="Counselor email"
                    />
                  </div>
                </div>

                <div class="col-span-2">
                  <label
                    for="type"
                    class="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Type
                  </label>
                  <select
                    onChange={handleChange}
                    name="branchId"
                    id="branchId"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  >
                    {types &&
                      types?.map((c, i) => (
                        <option key={i} value={c}>
                          {c}
                        </option>
                      ))}
                  </select>
                </div>
                <div class="col-span-2">
                  <label
                    for="review"
                    class="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Review
                  </label>

                  <textarea
                    onChange={handleChange}
                    rows={4}
                    name="review"
                    id="review"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                    placeholder="Write review here..."
                  />
                </div>
                <div class="flex items-center justify-center">
                  <button
                    type="submit"
                    class="bg-primary  text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline w-fit"
                  >
                    Save Sessions
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="opacity-70 fixed inset-0 z-40 bg-black"></div>
    </div>
  );
};

export default AddSessionsModel;
