import React, { useEffect, useState } from "react";
import BannersTable from "../tables/banners/BannersTable";
import { FaPlus } from "react-icons/fa";
import { API } from "../../API";
import AddBannerModel from "../models/banner/AddBannerModel";

const SuperAdminBanners = () => {
  const [addBannerPopupShow, setAddBannerPopupShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [receptionist, setReceptionist] = useState([]);

  const getAllBanners = async () => {
    try {
      setReceptionist([]);
      setIsLoading(true);
      const { data } = await API.get("/banner/get");
      setReceptionist(data.data.banner);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error in getAllBranch :", error);
    }
  };

  useEffect(() => {
    getAllBanners();
  }, []);

  return (
    <div className="p-4">
      <div className="flex justify-end items-center">
        <div
          onClick={() => setAddBannerPopupShow(true)}
          class="flex items-center text-white gap-2 bg-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center cursor-pointer"
          href={"/product/add"}
        >
          <FaPlus /> Add Banner
        </div>
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center h-[50vh]">
          <div class="w-16 h-16 border-8 border-dashed rounded-full animate-spin border-primary"></div>
        </div>
      ) : receptionist.length > 0 ? (
        <BannersTable data={receptionist} reload={getAllBanners} />
      ) : (
        <div className="flex justify-center items-center h-[50vh]">
          <div class="text-xl">
            Data Not Found.
          </div>
        </div>
      )}

      <AddBannerModel
        reload={getAllBanners}
        show={addBannerPopupShow}
        close={setAddBannerPopupShow}
      />
    </div>
  );
};

export default SuperAdminBanners;
