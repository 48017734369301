import "./App.css";
import PrivateLayout from "./components/PrivateLayout";
import { Routes, Route } from "react-router-dom";
import Unauthorized from "./components/Unauthorized";
import RequireAuth from "./components/RequiredAuth";
import { AuthProvider } from "./context/AuthProvider";
import Login from "./auth/Login";
import SuperAdminDashbord from "./components/super_admin/SuperAdminDashbord";
import SuperAdminBranch from "./components/super_admin/SuperAdminBranch";
import SuperAdminTutors from "./components/super_admin/SuperAdminTutors";
import SuperAdminStudents from "./components/super_admin/SuperAdminStudents";
import SuperAdminBanners from "./components/super_admin/SuperAdminBanners";
import SuperAdminCounselors from "./components/super_admin/SuperAdminCounselors";
import SuperAdminReceptionist from "./components/super_admin/SuperAdminReceptionist";
import SubAdminDashbord from "./components/sub_admin/SubAdminDashbord";
import SubAdminCounselors from "./components/sub_admin/SubAdminCounselors";
import SubAdminTutors from "./components/sub_admin/SubAdminTutors";
import SubAdminStudents from "./components/sub_admin/SubAdminStudents";
import SubAdminBanners from "./components/sub_admin/SubAdminBanners";
import CounselorDashbord from "./components/counselor/CounselorDashbord";
import CounselorSessions from "./components/counselor/CounselorSessions";
import SubAdminProfile from "./components/sub_admin/SubAdminProfile";
import SuperAdminProfile from "./components/super_admin/SuperAdminProfile";
import SuperAdminCourse from "./components/super_admin/SuperAdminCourse";
import SuperAdminCountry from "./components/super_admin/SuperAdminCountry";
import SubAdminCourse from "./components/sub_admin/SubAdminCourse";
import SubAdminCountry from "./components/sub_admin/SubAdminCountry";

function App() {
  return (
    <>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
        </Routes>

        <Routes>
          <Route path="/" element={<PrivateLayout />}>
            <Route element={<RequireAuth allowedRoles={["SUPER_ADMIN"]} />}>
              <Route path="/admin-dashboard" element={<SuperAdminDashbord />} />
              <Route path="/admin-branch" element={<SuperAdminBranch />} />
              <Route
                path="/admin-receptionist"
                element={<SuperAdminReceptionist />}
              />
              <Route
                path="/admin-counselors"
                element={<SuperAdminCounselors />}
              />
              <Route path="/admin-tutors" element={<SuperAdminTutors />} />
              <Route path="/admin-students" element={<SuperAdminStudents />} />
              <Route path="/admin-banners" element={<SuperAdminBanners />} />
              <Route path="/admin-course" element={<SuperAdminCourse />} />
              <Route path="/admin-country" element={<SuperAdminCountry />} />
              <Route
                path="/super_admin-profile"
                element={<SuperAdminProfile />}
              />
            </Route>
            <Route element={<RequireAuth allowedRoles={["RECEPTIONIST"]} />}>
              <Route
                path="/receptionist-dashboard"
                element={<SubAdminDashbord />}
              />
              <Route
                path="/receptionist-counselors"
                element={<SubAdminCounselors />}
              />
              <Route path="/receptionist-tutors" element={<SubAdminTutors />} />
              <Route
                path="/receptionist-students"
                element={<SubAdminStudents />}
              />
              <Route
                path="/receptionist-banners"
                element={<SubAdminBanners />}
              />
              <Route
                path="/receptionist-profile"
                element={<SubAdminProfile />}
              />
              <Route path="/receptionist-course" element={<SubAdminCourse />} />
              <Route path="/receptionist-country" element={<SubAdminCountry />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={["COUNSELOR"]} />}>
              <Route
                path="/counselor-dashboard"
                element={<CounselorDashbord />}
              />
              <Route
                path="/counselor-session"
                element={<CounselorSessions />}
              />
            </Route>
          </Route>
        </Routes>
      </AuthProvider>
    </>
  );
}

export default App;
