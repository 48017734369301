import React from "react";
import moment from "moment";

const ViewSessionModel = ({ session, show, close }) => {
  return (
    <div className={`${show ? "" : "hidden"}`}>
      <div class="justify-center items-center flex overflow-x-hidden overflow-y-auto  fixed inset-0 z-50 outline-none focus:outline-none">
        <div class="w-72 sm:w-96 xl:w-4/5 my-6 mx-auto  relative bg-white  rounded-2xl flex flex-col">
          <div class="bg-white w-full rounded-lg  shadow-lg min-h-[70vh] max-h-[80vh] overflow-y-auto relative">
            <div class="items-center z-10 n   flex justify-between flex-wrap md:flex-nowrap py-2 md:py-0 px-2 font-bold text-center text-lg md:text-2xl  bg-primary sticky top-0 m-3 rounded">
              <h2 class="grow text-lg lg:text-2xl font-bold text-white  text-center">
                <span className="bg-gradient-to-r  bg-clip-text">
                  View Session
                </span>
              </h2>
              <button
                type="button"
                onClick={() => close(false)}
                className=" rounded-md md:p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black"
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                  style={{ color: "white" }}
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>

            <div className="p-4">
              <div className="overflow-x-auto overflow-y-auto relative h-full">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 border">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-100 ">
                    <tr>
                      <th scope="col" class="px-6 py-3 rounded-s-lg">
                        Key
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Value
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="bg-white">
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                      >
                        Student Name
                      </th>
                      <td class="px-6 py-4">{session.studentId.name}</td>
                    </tr>
                    <tr class="bg-white">
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                      >
                        Counselor Name
                      </th>
                      <td class="px-6 py-4">{session.counselorId.name}</td>
                    </tr>
                    <tr class="bg-white">
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                      >
                        Start Time
                      </th>
                      <td class="px-6 py-4">{moment(session.startTime)?.format("DD-MM-YYYY  hh:mm A")}</td>
                    </tr>
                    <tr class="bg-white ">
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                      >
                        End Time
                      </th>
                      <td class="px-6 py-4">{moment(session.endTime)?.format("DD-MM-YYYY  hh:mm A")}</td>
                    </tr>
                    <tr class="bg-white ">
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                      >
                        Type
                      </th>
                      <td class="px-6 py-4">{session.type}</td>
                    </tr>
                    <tr class="bg-white ">
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                      >
                        Link
                      </th>
                      <td class="px-6 py-4">{session.link}</td>
                    </tr>
                    <tr class="bg-white ">
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                      >
                        Status
                      </th>
                      <td class="px-6 py-4">{session.status}</td>
                    </tr>
                    <tr class="bg-white ">
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                      >
                        Review
                      </th>
                      <td class="px-6 py-4">{session.review}</td>
                    </tr>
                    <tr class="bg-white ">
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                      >
                        Reason
                      </th>
                      <td class="px-6 py-4">{session.reason}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-70 fixed inset-0 z-40 bg-black"></div>
    </div>
  );
};

export default ViewSessionModel;
