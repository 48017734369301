import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API } from "../../API";
import { LiaChalkboardTeacherSolid } from "react-icons/lia";
import { PiFrameCorners, PiStudentFill } from "react-icons/pi";
import { GiTeacher } from "react-icons/gi";

const CounselorDashbord = () => {
  const [dashboardData, setDashboardData] = useState({
    totalSessions: 0,
    completedSessions: 0,
    cancelledSessions: 0,
    pendingSessions: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  const array = [  
    {
      title: "Total Sessions",
      name: "totalSessions",
      link: "/receptionist-counselors",
      icon: <LiaChalkboardTeacherSolid className="h-12 w-12 text-white" />,
    },
    {
      title: "Completed Sessions",
      name: "completedSessions",
      link: "/receptionist-tutors",
      icon: <GiTeacher className="h-10 w-10 text-white" />,
    },
    {
      title: "Cancelled Sessions",
      name: "cancelledSessions",
      link: "/receptionist-students",
      icon: <PiStudentFill className="h-12 w-12 text-white" />,
    },
    {
      title: "Pending Sessions",
      name: "pendingSessions",
      link: "/receptionist-banners",
      icon: <PiFrameCorners className="h-12 w-12 text-white" />,
    },
  ];

  const getDashboardData = async () => {
    try {
      // setIsLoading(true);
      const res = await API.get("/user/counselor-dashboard");
      if (res.data.success) {
        // setDashboardData(res.data.data);
      }
      // setIsLoading(true);
    } catch (error) {
      setIsLoading(true);
      console.log(error);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <div className="p-6">
      <div class="grid grid-cols-1 md:grid-cols-5 lg:grid-rows-5 gap-6 mb-6">
        {isLoading && array?.length <= 0 && !dashboardData ? (
          <div className="flex justify-center items-center h-[50vh]">
            <div class="w-16 h-16 border-8 border-dashed rounded-full animate-spin border-primary"></div>
          </div>
        ) : (
          <>
            {array?.map((item) => (
              <div
                class="bg-white rounded-xl  p-6 shadow-xl hover:shadow-md shadow-black/5 hover:shadow-red-300"
              >
                <div class="flex justify-between my-3">
                  <div className="flex justify-between w-full">
                    <div>
                      <div class="text-3xl font-semibold mb-1">
                        {dashboardData && dashboardData[`${item?.name}`]}
                      </div>
                      <div class="text-lg font-medium capitalize break-word">
                        {item?.title}
                      </div>
                    </div>
                    <div class="flex h-20 w-20 items-center justify-center rounded-full bg-primary">
                      {item?.icon}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default CounselorDashbord;
