import React, { useEffect, useState } from "react";
import { API } from "../../../API";
import { toast } from "react-toastify";
import { MultiSelect } from "react-multi-select-component";

const AddBannerModel = ({ show, close, reload }) => {
  const [branch, setBranch] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState([]);
  const [options, setOptions] = useState([]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const getAllBranch = async () => {
    try {
      const { data } = await API.get("/branch/get");
      setBranch(data.data.branch);
      const opt = [];
      data.data.branch?.map((b) => {
        opt.push({
          label: b?.name,
          value: b?._id,
        });
      });
      setOptions(opt);
    } catch (error) {
      console.log("error in getAllBranch :", error);
    }
  };

  useEffect(() => {
    getAllBranch();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!selectedFile) {
        toast.error("Please select a file first!");
        return;
      }
      setSubmitting(true);
      const branchIdList = selectedBranch?.map((b) => ({
        branchId: b?.value,
      }));
      const data = new FormData();
      data.append("banner", selectedFile);
      data.append("branchId", JSON.stringify(branchIdList));

      const res = await API.post("/banner/add", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.data.success) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      setSelectedBranch([]);
      reload();
      close(false);
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      toast.error(error.response.data.message);
      console.log("error in add banner", error);
    }
  };

  return (
    <div className={`${show ? "" : "hidden"}`}>
      <div class="justify-center items-center flex overflow-x-hidden overflow-y-auto  fixed inset-0 z-50 outline-none focus:outline-none">
        <div class="w-72 sm:w-96 xl:w-1/3 my-6 mx-auto max-w-2xl relative bg-white  rounded-2xl flex flex-col">
          <div class="bg-white w-full rounded-lg  shadow-lg  max-h-[80vh] min-h-[42vh] overflow-y-auto relative">
            <div class="items-center flex justify-between flex-wrap md:flex-nowrap py-2 md:py-0 px-2 font-bold text-center text-lg md:text-2xl  bg-primary sticky top-0 m-3 rounded">
              <h2 class="grow text-lg lg:text-2xl font-bold text-white  text-center">
                <span class="bg-gradient-to-r  bg-clip-text">Add Banner</span>
              </h2>
              <button
                type="button"
                onClick={() => close(false)}
                class=" rounded-md md:p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black"
              >
                <svg
                  class="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                  style={{ color: "white" }}
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            <form
              class="flex flex-col justify-center items-center"
              onSubmit={handleSubmit}
            >
              <div class=" space-y-4 px-4 pt-2 pb-4 w-full">
                <div>
                  <label
                    for="image"
                    class="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Image
                  </label>
                  <label for="small-file-input" class="sr-only">
                    Choose file
                  </label>
                  <input
                    type="file"
                    name="image"
                    id="image"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={handleFileChange}
                    class="block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none file:bg-gray-200 file:border-0 file:me-4 file:py-2 file:px-4"
                  />
                </div>
                <div>
                  <label
                    for="branchId"
                    class="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Select Branch
                  </label>
                  <MultiSelect
                    options={options}
                    value={selectedBranch}
                    onChange={setSelectedBranch}
                    labelledBy="Select"
                  />
                </div>
              </div>

              <div class="flex mt-[30%] mb-4 items-center justify-center">
                <button
                  type="submit"
                  class="bg-gradient-to-r bg-primary  text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline w-fit"
                >
                  {submitting ? (
                    <div class="flex justify-center items-center">
                      <div class="w-5 h-5 rounded-full border-4 border-gray-400 border-t-white animate-spin"></div>
                    </div>
                  ) : (
                    "Save Banner"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="opacity-70 fixed inset-0 z-40 bg-black"></div>
    </div>
  );
};

export default AddBannerModel;
