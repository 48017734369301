import React, { useState } from "react";
import { API } from "../../API";
import { toast } from "react-toastify";

const CheckboxComponent = ({ reload, initialStatus, id }) => {
  const [isChecked, setIsChecked] = useState(initialStatus);
  const handleCheckboxChange = async (checked) => {
    try {
      setIsChecked(checked);
      const { data } = await API.patch(`/banner/status/${id}`, {
        status: checked,
      });
      if (data.success) {
        toast.success(data.message);
        reload();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log("error in handleCheckboxChange : ", error);
    }
  };

  return (
    <label className="inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        checked={isChecked}
        onChange={(e) => handleCheckboxChange(e.target.checked)}
        className="sr-only peer"
      />
      <div className="relative w-14 h-7 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all peer-checked:bg-primary"></div>
    </label>
  );
};

export default CheckboxComponent;
