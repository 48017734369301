import React, { useState } from "react";
import { API } from "../../../API";
import { toast } from "react-toastify";
import DeleteConfirmation from "../../models/DeleteConfirmation";
import EditBranchModel from "../../models/branch/EditBranchModel";
import EditStudentModel from "../../models/student/EditStudentModel";
import AddPaymentModel from "../../models/student/AddPaymentModel";
import ViewPatmentModel from "../../models/student/ViewPatmentModel";
import EditStudentSubAdminModel from "../../models/student/EditStudentSubAdminModel";

const  StudentTableSubAdmin = ({ data, reload }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [editPopupShow, setEditPopupShow] = useState(false);
  const [paymentPopupShow, setPaymentPopupShow] = useState(false);
  const [viewPopupShow, setViewPopupShow] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState({});

  const deleteStudent = async (id) => {
    try {
      const { data } = await API.delete(`/user/delete-student/${id}`);
      if (data.success) {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
      setShowConfirmation(false);
      reload();
      setSelectedStudent({});
    } catch (error) {
      toast.error(error.response.data.message);
      console.log("error in deleteCategory :", error);
    }
  };
  return (
    <div>
      <div className="rounded-t text-white flex items-center justify-center mt-4 px-6 py-2 font-bold text-xl border-b border-gray-100 bg-primary">
        Student List
      </div>
      <div className="overflow-x-auto overflow-y-auto relative h-[85vh]">
        <table className="border-collapse table-auto table-striped relative min-w-full divide-y divide-gray-200">
          <thead className=" ">
            <tr className="sticky top-0 bg-gray-100 text-base font-bold text-gray-500 uppercase z-10">
              <th
                scope="col"
                className="px-6 py-3 text-left tracking-wider sticky left-0 bg-gray-100"
              >
                No
              </th>
              <th scope="col" className="px-6 py-3 text-left tracking-wider">
                Name
              </th>
              <th scope="col" className="px-6 py-3 text-left tracking-wider">
                Payment
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left min-w-40  tracking-wider"
              >
                Total Payment
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left min-w-52  tracking-wider"
              >
                Pending Payment
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left min-w-40  tracking-wider"
              >
                Username
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left min-w-40  tracking-wider"
              >
                Email
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left min-w-40  tracking-wider"
              >
                Phone
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left min-w-40  tracking-wider"
              >
                Country
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left min-w-40  tracking-wider"
              >
                Branch
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left min-w-40  tracking-wider"
              >
                Course
              </th>
              <th scope="col" className="px-6 py-3 text-center tracking-wider">
                Payment
              </th>
              <th scope="col" className="px-6 py-3 text-center tracking-wider">
                View
              </th>
              <th scope="col" className="px-6 py-3 text-center tracking-wider">
                Update
              </th>
              <th scope="col" className="px-6 py-3 text-center tracking-wider">
                Delete
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {data &&
              data?.map((student, i) => (
                <tr key={i} className="text-lg text-gray-900">
                  <td className="px-6 py-4 whitespace-nowrap sticky left-0 bg-white">
                    <div className="flex items-center">
                      <div>{i + 1}</div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div>{student?.name}</div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div>{student?.paymentAmount}</div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="min-w-40">
                      <div>{student?.totalPayment}</div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className=" min-w-52">
                      <div>
                        {student?.paymentAmount - student?.totalPayment}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 min-w-60">
                    <div>{student?.userName}</div>
                  </td>
                  <td className="px-6 py-4 min-w-60">
                    <div>{student?.email}</div>
                  </td>
                  <td className="px-6 py-4 min-w-60">
                    <div>{student?.phoneNo}</div>
                  </td>
                  <td className="px-6 py-4 min-w-60">
                    <div>{student?.country?.name}</div>
                  </td>
                  <td className="px-6 py-4 min-w-60">
                    <div>{student?.branchId?.name}</div>
                  </td>
                  <td className="px-6 py-4 min-w-60">
                    <div>{student?.courseId?.name}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span
                      onClick={() => {
                        setSelectedStudent(student);
                        setPaymentPopupShow(true);
                      }}
                      className="cursor-pointer inline-flex leading-5 text-white  bg-green-600 hover:bg-green-700  rounded-lg text-sm font-bold px-5 py-2.5 me-2 mb-2"
                    >
                      Add Payment
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span
                      onClick={() => {
                        setSelectedStudent(student);
                        setViewPopupShow(true);
                      }}
                      className="cursor-pointer inline-flex leading-5 text-white  bg-purple-600 hover:bg-purple-700  font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                    >
                      View
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span
                      onClick={() => {
                        setSelectedStudent(student);
                        setEditPopupShow(true);
                      }}
                      className="cursor-pointer inline-flex leading-5 text-white  bg-blue-600 hover:bg-blue-700  font-bold rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                    >
                      Update
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span
                      onClick={() => {
                        setSelectedStudent(student);
                        setShowConfirmation(true);
                      }}
                      className="cursor-pointer inline-flex leading-5 text-white  bg-primary hover:bg-red-700  font-bold rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                    >
                      Delete
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {showConfirmation && (
        <DeleteConfirmation
          msg={`Are you sure you want to delete ${selectedStudent?.name} student ?`}
          close={setShowConfirmation}
          task={() => deleteStudent(selectedStudent?._id)}
        />
      )}
      {editPopupShow && (
        <EditStudentSubAdminModel
          key={selectedStudent?.name}
          show={editPopupShow}
          close={setEditPopupShow}
          data={selectedStudent}
          reload={reload}
        />
      )}
      {paymentPopupShow && (
        <AddPaymentModel
          show={paymentPopupShow}
          close={setPaymentPopupShow}
          data={selectedStudent}
          reload={reload}
        />
      )}

      {viewPopupShow && (
        <ViewPatmentModel
          data={selectedStudent}
          show={viewPopupShow}
          close={setViewPopupShow}
        />
      )}
    </div>
  );
};

export default StudentTableSubAdmin;
