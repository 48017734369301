import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { SideBar } from "./SideBar";
import { Header } from "./Header";
import { useState } from "react";

const PrivateLayout = () => {
  const [isOpen, setIsOpen] = useState(true);
  const authToken = localStorage.getItem("authToken");
  if (!authToken) return <Navigate to="/login" />;
  return (
    <>
      {authToken && (
        <>
          <div className="h-screen bg-[#f1f5f9] flex flex-row">
            {isOpen && (
              <div>
                <SideBar />
              </div>
            )}
            <div className="grow w-full overflow-x-auto ">
              <div className="sticky top-0 z-20">
                <Header isOpen={isOpen} setIsOpen={setIsOpen} />
              </div>
              <div className="">
                <Outlet />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default PrivateLayout;
