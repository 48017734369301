import React, { useState } from "react";
import { API } from "../../../API";
import { toast } from "react-toastify";
import moment from "moment";
import EditSessionModel from "../../models/session/EditSessionModel";
import ViewSessionModel from "../../models/session/ViewSessionModel";

const SessionTable = ({ data, reload }) => {
  const [editPopupShow, setEditPopupShow] = useState(false);
  const [viewPopupShow, setViewPopupShow] = useState(false);
  const [selectedSession, setSelectedSession] = useState({});

  return (
    <div>
      <div className="rounded-t text-white flex items-center justify-center mt-4 px-6 py-2 font-bold text-xl border-b border-gray-100 bg-primary">
        Session List
      </div>

      <div className="overflow-x-auto overflow-y-auto relative h-[85vh]">
        <table className="border-collapse table-auto table-striped relative min-w-full divide-y divide-gray-200">
          <thead className=" ">
            <tr className="sticky top-0 bg-gray-100 text-base font-bold text-gray-500 uppercase z-10">
              <th
                scope="col"
                className="px-6 py-3 text-left tracking-wider sticky left-0 bg-gray-100"
              >
                No
              </th>
              <th scope="col" className="px-6 py-3 text-center tracking-wider">
                Student Name
              </th>
              <th scope="col" className="px-6 py-3 text-center tracking-wider">
                Date
              </th>
              <th scope="col" className="px-6 py-3 text-center tracking-wider">
                Start Time
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-center min-w-40  tracking-wider"
              >
                End Time
              </th>
              <th scope="col" className="px-6 py-3 text-left  tracking-wider">
                Status
              </th>
              <th scope="col" className="px-6 py-3 text-center tracking-wider">
                Type
              </th>
              <th scope="col" className="px-6 py-3 text-center tracking-wider">
                Link
              </th>
              <th scope="col" className="px-6 py-3 text-center tracking-wider">
                Review
              </th>
              <th scope="col" className="px-6 py-3 text-center tracking-wider">
                Reason
              </th>

              <th scope="col" className="px-6 py-3 text-center tracking-wider">
                View
              </th>
              <th scope="col" className="px-6 py-3 text-center tracking-wider">
                Update
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {data &&
              data?.map((session, i) => (
                <tr key={i} className="text-lg text-gray-900">
                  <td className="px-6 py-4 whitespace-nowrap sticky left-0 bg-white">
                    <div className="flex items-center">
                      <div>{i + 1}</div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div>{session?.studentId.name}</div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div>
                        {moment(session?.startTime).format("DD-MM-YYYY")}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div>{moment(session?.startTime).format("hh:mm A")}</div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="px-6 py-4 min-w-40">
                      <div>{moment(session?.endTime).format("hh:mm A")}</div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div>{session?.status}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div>{session?.type}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap max-w-20">
                    <div className="truncate ...">{session?.link}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap  max-w-20">
                    <div className="truncate ...">{session?.review}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap max-w-20">
                    <div className="truncate ...">
                      {session?.reason ? session?.reason : "-"}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span
                      onClick={() => {
                        setSelectedSession(session);
                        setViewPopupShow(true);
                      }}
                      className="cursor-pointer inline-flex leading-5 text-white  bg-purple-600 hover:bg-purple-700  font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                    >
                      View
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span
                      onClick={() => {
                        setSelectedSession(session);
                        setEditPopupShow(true);
                      }}
                      className="cursor-pointer inline-flex leading-5 text-white  bg-blue-600 hover:bg-blue-700  font-bold rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                    >
                      Update
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {editPopupShow && (
        <EditSessionModel
          key={selectedSession?.name}
          show={editPopupShow}
          close={setEditPopupShow}
          data={selectedSession}
          reload={reload}
        />
      )}
      {viewPopupShow && (
        <ViewSessionModel
          session={selectedSession}
          show={viewPopupShow}
          close={setViewPopupShow}
        />
      )}
    </div>
  );
};

export default SessionTable;
