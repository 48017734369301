import React, { useEffect, useState } from "react";
import { API } from "../../../API";
import { toast } from "react-toastify";
import moment from "moment";
import DeleteConfirmation from "../DeleteConfirmation";

const ViewPatmentModel = ({ reload, data, show, close }) => {
  const [payents, setPayments] = useState([]);
  const [currentPayents, setCurrentPayments] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectesPaymeny, setSelectesPaymeny] = useState("");
  const getAllPayment = async () => {
    try {
      setIsLoading(true);
      const res = await API.get(`/payment/get/${data?._id}`);
      setPayments(res.data.data.payment);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error in getAllBranch :", error);
    }
  };

  useEffect(() => {
    getAllPayment();
  }, []);

  const deletePayment = async (id) => {
    try {
      const { data } = await API.delete(`/payment/delete/${id}`);
      if (data.success) {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
      setShowConfirmation(false);
      reload();
      getAllPayment();
    } catch (error) {
      console.log("error in deletePayment :", error);
    }
  };

  const downloadPaymentSlip = async (id, name) => {
    try {
      setIsLoading(true);
      const res = await API.get(`/payment/slip/${id}`, {
        responseType: "blob",
      });
      const url = URL.createObjectURL(res.data);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${name}-payment-slip.pdf`;
      link.click();
      URL.revokeObjectURL(url);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error in downloadPaymentSlip :", error);
    }
  };

  console.log("selectesPaymeny", selectesPaymeny);

  return (
    <div className={`${show ? "" : "hidden"}`}>
      <div class="justify-center items-center flex overflow-x-hidden overflow-y-auto  fixed inset-0 z-50 outline-none focus:outline-none">
        <div class="w-72 sm:w-96 xl:w-4/5 my-6 mx-auto  relative bg-white  rounded-2xl flex flex-col">
          <div class="bg-white w-full rounded-lg  shadow-lg min-h-[70vh] max-h-[80vh] overflow-y-auto relative">
            <div class="items-center z-10 n   flex justify-between flex-wrap md:flex-nowrap py-2 md:py-0 px-2 font-bold text-center text-lg md:text-2xl  bg-primary sticky top-0 m-3 rounded">
              <h2 class="grow text-lg lg:text-2xl font-bold text-white  text-center">
                <span class="bg-gradient-to-r  bg-clip-text">
                  {data?.name} Payment Details
                </span>
              </h2>
              <button
                type="button"
                onClick={() => close(false)}
                class=" rounded-md md:p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black"
              >
                <svg
                  class="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                  style={{ color: "white" }}
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="p-4">
              <div className="overflow-x-auto overflow-y-auto relative h-full">
                <table className="border-collapse table-auto table-striped relative min-w-full divide-y divide-gray-200">
                  <thead className=" ">
                    <tr className="sticky top-0 bg-gray-100 text-base font-bold text-gray-500 uppercase">
                      <th
                        scope="col"
                        className="px-6 py-3 text-left tracking-wider sticky left-0 bg-gray-100"
                      >
                        No
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left tracking-wider"
                      >
                        Student Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left min-w-40  tracking-wider"
                      >
                        Amount
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left min-w-40  tracking-wider"
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left min-w-40  tracking-wider"
                      >
                        Receipt
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left min-w-40  tracking-wider"
                      >
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {payents &&
                      payents?.map((u, i) => (
                        <tr key={i} className="text-lg text-gray-900">
                          <td className="px-6 py-4 whitespace-nowrap sticky left-0 bg-white">
                            <div className="flex items-center">
                              <div>{i + 1}</div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <div>{u?.studentId?.name}</div>
                            </div>
                          </td>
                          <td className="px-6 py-4 min-w-60">
                            <div>{u?.amount}</div>
                          </td>
                          <td className="px-6 py-4 min-w-60">
                            <div>
                              {moment(u?.createdAt).format("DD-MM-YYYY")}
                            </div>
                          </td>
                          <td className="px-6 py-4 min-w-60">
                            <button
                              disabled={isLoading}
                              onClick={() => {
                                downloadPaymentSlip(u?._id, u?.studentId?.name);
                                setSelectesPaymeny(u?._id);
                              }}
                              className="cursor-pointer inline-flex leading-5 text-white  bg-green-600 hover:bg-green-700  rounded-lg text-sm font-bold px-5 py-2.5 me-2 mb-2"
                            >
                              {selectesPaymeny === u?._id && isLoading ? (
                                <div class="flex justify-center items-center">
                                  <div class="w-5 h-5 rounded-full border-4 border-gray-400 border-t-white animate-spin"></div>
                                </div>
                              ) : (
                                "Download"
                              )}
                              {console.log(
                                selectesPaymeny === u?._is && isLoading
                              )}
                            </button>
                          </td>
                          <td className="px-6 py-4 min-w-60">
                            <span
                              onClick={() => {
                                setCurrentPayments(u);
                                setShowConfirmation(true);
                              }}
                              className="cursor-pointer inline-flex leading-5 text-white  bg-primary hover:bg-red-700  font-bold rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                            >
                              Delete
                            </span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="opacity-70 fixed inset-0 z-40 bg-black"></div>
      {showConfirmation && (
        <DeleteConfirmation
          msg={`Are you sure you want to delete ${currentPayents?.amount} payment ?`}
          close={setShowConfirmation}
          task={() => deletePayment(currentPayents?._id)}
        />
      )}
    </div>
  );
};

export default ViewPatmentModel;
