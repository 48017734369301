import { createContext, useEffect, useState } from "react";
import { API } from "../API";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    user: JSON.parse(localStorage.getItem("user")),
    accessToken: localStorage.getItem("authToken"),
  });
  const [userRole, setUserRole] = useState([]);
  const getCurrentUser = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user?._id) {
      const authToken = localStorage.getItem("authToken");
      try {
        const { data } = await API.get(`/user/me`);
        if (data.success) {
          setAuth({
            ...auth,
            user: data.data.user,
            accessToken: authToken,
            role: [data.data.user.role],
          });
          setUserRole([data.data.user.role]);
        }
      } catch (error) {
        console.log("error in getCurrentUser", error);
      }
    }
    // setAuth({ user: user, role: [role], accessToken: accessToken });
    // setAuth(user)
  };
  useEffect(() => {
    getCurrentUser();
  }, []);
  const setCurrentUser = (data) => {
    setAuth(data);
  };
  return (
    <AuthContext.Provider
      value={{ auth, userRole, setAuth: setCurrentUser, setUserRole }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
