import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { API } from "../../API";
import AddTutorModel from "../models/tutor/AddTutorModel";
import TutorsTable from "../tables/tutor/TutorsTable";
import moment from "moment";
import { MdDownload } from "react-icons/md";

const SuperAdminTutors = () => {
  const [tutors, setTutors] = useState([]);
  const [filterArray, setFilterArray] = useState([]);
  const [addTutorPopupShow, setAddTutorPopupShow] = useState(false);
  const [branch, setBranch] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("ALL");
  const [isDownloadXLSX, setIsDownloadXLSX] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const getAllBranch = async () => {
    try {
      const { data } = await API.get("/branch/get");
      setBranch(data.data.branch);
    } catch (error) {
      console.log("error in getAllBranch :", error);
    }
  };

  const getAllTutors = async () => {
    try {
      setIsLoading(true)
      const { data } = await API.get(
        `/user/all?role=TUTOR&branchId=${selectedBranch}`
      );
      setTutors(data.data);
      setFilterArray(data.data);
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log("error in getAllTutors :", error);
    }
  };

  useEffect(() => {
    getAllBranch();
  }, []);

  useEffect(() => {
    if (selectedBranch) {
      getAllTutors();
    }
  }, [selectedBranch]);

  const handleSearch = (val) => {
    if (!val) {
      setFilterArray(tutors);
    } else {
      const data = tutors?.filter((b) =>
        b?.name?.toLowerCase()?.includes(val?.toLowerCase())
      );
      setFilterArray(data);
    }
  };

  const downloadXLSXFile = async () => {
    try {
      setIsDownloadXLSX(true);
      const { data } = await API.get(
        `/user/xlsx?role=TUTOR&branchId=${selectedBranch}`,
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${moment(new Date()).format("DD-MM-YYYY")}_${
          selectedBranch == "ALL"
            ? "all"
            : branch?.find((b) => b?._id === selectedBranch)?.name
        }_tutors.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
      setIsDownloadXLSX(false);
    } catch (error) {
      setIsDownloadXLSX(false);
      console.log("error in downloadXLSXFile :", error);
    }
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center">
        <div className="w-[25%]">
          <select
            id="small"
            onChange={(e) => setSelectedBranch(e.target.value)}
            class="w-full h-10 p-1 text-lg border border-gray-300 rounded-lg bg-gray-50 focus:border-2 focus:border-primary"
          >
            <option
              className={`text-lg h-10 ${
                "ALL" == selectedBranch ? "bg-primary text-white" : ""
              }`}
              value="ALL"
            >
              All
            </option>
            {branch &&
              branch.map((b, i) => (
                <option
                  key={i}
                  className={`text-lg h-10 ${
                    b?._id == selectedBranch ? "bg-primary text-white" : ""
                  }`}
                  value={b?._id}
                >
                  {b?.name}
                </option>
              ))}
          </select>
        </div>
        <div className="flex gap-4">
          <button
            onClick={() => downloadXLSXFile()}
            disabled={isDownloadXLSX}
            class={`${
              isDownloadXLSX ? "cursor-not-allowed" : "cursor-pointer"
            } flex items-center text-white gap-2 bg-green-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
          >
            {isDownloadXLSX ? (
              <div class="flex justify-center items-center">
                <div class="w-5 h-5 rounded-full border-4 border-gray-400 border-t-white animate-spin"></div>
              </div>
            ) : (
              <div className="flex justify-center items-center gap-1">
                <MdDownload className="h-5 w-5" /> download excel
              </div>
            )}
          </button>
          <div
            onClick={() => setAddTutorPopupShow(true)}
            class="flex items-center text-white gap-2 bg-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center cursor-pointer"
          >
            <FaPlus /> Add tutor
          </div>
        </div>
      </div>
      <div className="flex justify-start mt-4 w-[25%]">
        <div class="relative flex items-center w-full h-10 rounded-lg focus-within:shadow-lg bg-white focus-within:border-2  focus-within:border-primary border-2 overflow-hidden">
          <div class="grid place-items-center h-full w-12 text-gray-300">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>

          <input
            class="peer h-full w-full outline-none text-base text-gray-700 pr-2"
            type="text"
            id="search"
            autoComplete="off"
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Search something.."
          />
        </div>
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center h-[50vh]">
          <div class="w-16 h-16 border-8 border-dashed rounded-full animate-spin border-primary"></div>
        </div>
      ) : filterArray.length > 0 ? (
        <TutorsTable data={filterArray} reload={getAllTutors} />
      ) : (
        <div className="flex justify-center items-center h-[50vh]">
          <div class="text-xl">
            Data Not Found.
          </div>
        </div>
      )}

      {addTutorPopupShow && (
        <AddTutorModel
          reload={getAllTutors}
          show={addTutorPopupShow}
          close={setAddTutorPopupShow}
        />
      )}
    </div>
  );
};

export default SuperAdminTutors;
