import React, { useEffect, useState } from "react";
import { API } from "../../../API";
import { toast } from "react-toastify";
import moment from "moment";

const EditSessionModel = ({ data, show, close, reload }) => {
  const [branch, setBranch] = useState(["COMPLETED", "CANCELLED", "PENDING"]);
  const [country, setCountry] = useState([]);
  const [course, setCourse] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    studentName: "",
    startTime: "",
    endTime: "",
    status: "",
    type: "",
    review: "",
    reason: "",
    link: "",
  });

  useEffect(() => {
    setFormData({
      studentName: data?.studentId.name ? data?.studentId.name : "",
      startTime: data?.startTime ? data?.startTime : "",
      endTime: data?.endTime ? data?.endTime : "",
      status: data?.status ? data?.status : "",
      type: data?.type ? data?.type : "",
      review: data?.review ? data?.review : "",
      reason: data?.reason ? data?.reason : "",
      link: data?.link ? data?.link : "",
    });
  }, [data]);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);

      const dataToSend = {
        status: formData?.status,
        review: formData?.review,
        reason: formData?.reason,
        link: formData?.link,
      };
      const res = await API.put(`/session/edit/${data?._id}`, dataToSend);
      if (res.data.success) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      setFormData({
        studentName: "",
        startTime: "",
        endTime: "",
        status: "",
        type: "",
        review: "",
        reason: "",
        link: "",
      });
      reload();
      close(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message);
      console.log("error in edit session", error);
    }
  };

  return (
    <div className={`${show ? "" : "hidden"}`}>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto  fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="w-72 sm:w-96 xl:w-1/3 my-6 mx-auto max-w-2xl relative bg-white  rounded-2xl flex flex-col">
          <div className="bg-white w-full rounded-lg  shadow-lg  max-h-[80vh] overflow-y-auto relative">
            <div className="items-center flex justify-between flex-wrap md:flex-nowrap py-2 md:py-0 px-2 font-bold text-center text-lg md:text-2xl  bg-primary sticky top-0 m-3 rounded">
              <h2 className="grow text-lg lg:text-2xl font-bold text-white  text-center">
                <span className="bg-gradient-to-r  bg-clip-text">
                  Edit Session
                </span>
              </h2>
              <button
                type="button"
                onClick={() => close(false)}
                className=" rounded-md md:p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black"
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                  style={{ color: "white" }}
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            <form
              className="flex justify-center items-center"
              onSubmit={handleSubmit}
            >
              <div className="space-y-2 px-4 pt-2 pb-4 w-full ">
                <div>
                  <label
                    for="name"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Student Name
                  </label>
                  <input
                    defaultValue={formData?.studentName}
                    type="text"
                    name="name"
                    autoComplete="off"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                    disabled
                  />
                </div>
                <div>
                  <label
                    for="email"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Type
                  </label>
                  <input
                    disabled
                    onChange={handleChange}
                    defaultValue={formData?.type}
                    type="text"
                    name="type"
                    autoComplete="off"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  />
                </div>
                <div className="flex justify-center items-center gap-4">
                  <div className="w-full">
                    <label
                      for="phoneNo"
                      className="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      Start Time
                    </label>
                    <input
                      value={moment(formData?.startTime).format(
                        "DD-MM-YYYY hh:mm A"
                      )}
                      type="text"
                      name="phoneNo"
                      id="phoneNo"
                      autoComplete="off"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                      disabled
                    />
                  </div>
                  <div className="w-full">
                    <label
                      for="paymentAmount"
                      className="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      End Time
                    </label>
                    <input
                      value={moment(formData?.endTime).format(
                        "DD-MM-YYYY hh:mm A"
                      )}
                      type="text"
                      name="startTime"
                      autoComplete="off"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                      disabled
                    />
                  </div>
                </div>

                <div>
                  <label
                    for="name"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Link
                  </label>
                  <input
                    onChange={handleChange}
                    defaultValue={formData?.link}
                    type="text"
                    name="link"
                    autoComplete="off"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                    placeholder="Enter link"
                  />
                </div>

                <div className="col-span-2">
                  <label
                    for="address"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Status
                  </label>
                  <select
                    onChange={handleChange}
                    defaultValue={formData?.status}
                    name="status"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  >
                    {branch &&
                      branch?.map((c, i) => (
                        <option key={i} value={c}>
                          {c}
                        </option>
                      ))}
                  </select>
                </div>

                <div>
                  <label
                    for="name"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Review
                  </label>
                  <input
                    onChange={handleChange}
                    defaultValue={formData?.review}
                    type="text"
                    name="review"
                    autoComplete="off"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                    placeholder="Enter Review"
                  />
                </div>

                <div>
                  <label
                    for="name"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Reason
                  </label>
                  <input
                    onChange={handleChange}
                    defaultValue={formData?.reason}
                    type="text"
                    name="reason"
                    autoComplete="off"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                    placeholder="Enter cancellation reason"
                  />
                </div>

                <div className="flex items-center justify-center">
                  <button
                    type="submit"
                    className="bg-primary  text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline w-fit"
                  >
                    {isLoading ? (
                      <div className="flex justify-center items-center">
                        <div className="w-5 h-5 rounded-full border-4 border-gray-400 border-t-white animate-spin"></div>
                      </div>
                    ) : (
                      <span>Save Student</span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="opacity-70 fixed inset-0 z-40 bg-black"></div>
    </div>
  );
};

export default EditSessionModel;
