import React, { useState } from "react";
import { API } from "../../../API";
import { toast } from "react-toastify";
import DeleteConfirmation from "../../models/DeleteConfirmation";
import EditReceptionistModel from "../../models/receptionist/EditReceptionistModel";
import PasswordChangeModel from "../../models/password/PasswordChangeModel";

const ReceptionistTable = ({ data, reload }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [editPopupShow, setEditPopupShow] = useState(false);
  const [passwordPopupShow, setPasswordPopupShow] = useState(false);
  const [selectedReceptionist, setSelectedReceptionist] = useState({});

  const deleteReceptionist = async (id) => {
    try {
      const { data } = await API.delete(`/user/delete-receptionist/${id}`);
      if (data.success) {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
      setShowConfirmation(false);
      reload();
      setSelectedReceptionist({});
    } catch (error) {
      toast.error(error.response.data.message);
      console.log("error in deleteReceptionist :", error);
    }
  };

  return (
    <div>
      <div className="rounded-t text-white flex items-center justify-center mt-4 px-6 py-2 font-bold text-xl border-b border-gray-100 bg-primary">
        Branch Admin List
      </div>
      <div className="overflow-x-auto overflow-y-auto relative h-[85vh]">
        <table className="border-collapse table-auto table-striped relative min-w-full divide-y divide-gray-200">
          <thead className=" ">
            <tr className="sticky top-0 bg-gray-100 text-base font-bold text-gray-500 uppercase z-10">
              <th
                scope="col"
                className="px-6 py-3 text-left tracking-wider sticky left-0 bg-gray-100"
              >
                No
              </th>
              <th scope="col" className="px-6 py-3 text-left tracking-wider">
                Name
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left min-w-40  tracking-wider"
              >
                Username
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left min-w-40  tracking-wider"
              >
                Email
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left min-w-40  tracking-wider"
              >
                phone
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left min-w-40  tracking-wider"
              >
                Branch
              </th>
              <th scope="col" className="px-6 py-3 text-center tracking-wider">
                Update
              </th>
              <th scope="col" className="px-6 py-3 text-center tracking-wider">
                Password
              </th>
              <th scope="col" className="px-6 py-3 text-center tracking-wider">
                Delete
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {data &&
              data?.map((receptionist, i) => (
                <tr key={i} className="text-lg text-gray-900">
                  <td className="px-6 py-4 whitespace-nowrap sticky left-0 bg-white">
                    <div className="flex items-center">
                      <div>{i + 1}</div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div>{receptionist?.name}</div>
                    </div>
                  </td>
                  <td className="px-6 py-4 min-w-60">
                    <div>{receptionist?.userName}</div>
                  </td>
                  <td className="px-6 py-4 min-w-60">
                    <div>{receptionist?.email}</div>
                  </td>
                  <td className="px-6 py-4 min-w-60">
                    <div>{receptionist?.phoneNo}</div>
                  </td>
                  <td className="px-6 py-4 min-w-60">
                    <div>{receptionist?.branchId?.name}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span
                      onClick={() => {
                        setSelectedReceptionist(receptionist);
                        setEditPopupShow(true);
                      }}
                      className="cursor-pointer inline-flex leading-5 text-white  bg-blue-600 hover:bg-blue-700  font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                    >
                      Update
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span
                      onClick={() => {
                        setSelectedReceptionist(receptionist);
                        setPasswordPopupShow(true);
                      }}
                      className="cursor-pointer inline-flex leading-5 text-white  bg-yellow-500 hover:bg-yellow-600  font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                    >
                      Change Password
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span
                      onClick={() => {
                        setSelectedReceptionist(receptionist);
                        setShowConfirmation(true);
                      }}
                      className="cursor-pointer inline-flex leading-5 text-white  bg-primary hover:bg-red-700  font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                    >
                      Delete
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {showConfirmation && (
        <DeleteConfirmation
          msg={`Are you sure you want to delete branch ${selectedReceptionist?.name} ?`}
          close={setShowConfirmation}
          task={() => deleteReceptionist(selectedReceptionist?._id)}
        />
      )}

      {editPopupShow && (
        <EditReceptionistModel
          key={selectedReceptionist?.name}
          show={editPopupShow}
          close={setEditPopupShow}
          data={selectedReceptionist}
          reload={reload}
        />
      )}
      {passwordPopupShow && (
        <PasswordChangeModel
          show={passwordPopupShow}
          close={setPasswordPopupShow}
          data={selectedReceptionist}
          reload={reload}
        />
      )}
    </div>
  );
};

export default ReceptionistTable;
