import React, { useEffect, useState } from "react";
import { API } from "../../API";
import { FaPlus } from "react-icons/fa";
import AddSessionsModel from "../models/counselorAdmin/AddSessionsModel";
import SessionTable from "../tables/sessions/SessionTable";

const CounselorSessions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [sessionsList, setSessionsList] = useState([]);
  const [addSessionPopupShow, setAddSessionPopupShow] = useState(false);
  const [filterArray, setFilterArray] = useState([]);
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");
  const [date, setDate] = useState("");

  const statusArray = ["ALL", "PENDING", "COMPLETED", "CANCELLED"];
  const typeArray = ["ALL", "ONLINE", "OFFLINE", "MANUAL"];

  const getAllSessions = async (type, status, date) => {
    try {
      setIsLoading(true);
      const obj = {};
      if (type) {
        obj.type = type;
      }
      if (status) {
        obj.status = status;
      }
      if (date) {
        obj.date = date;
      }

      const { data } = await API.post("/session/get-list", obj);

      setSessionsList(data.data.sessions);
      setFilterArray(data.data.sessions);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error in getAllSessions :", error);
    }
  };

  useEffect(() => {
    getAllSessions(type, status, date);
  }, [type, status, date]);

  return (
    <div className="p-4">
      <div className="flex justify-between items-center">
        <div className="w-[25%]">
          <div>Select Status :</div>
          <select
            onChange={(e) => setStatus(e.target.value)}
            id="small"
            class="w-full h-10 p-1 text-lg border border-gray-300 rounded-lg bg-gray-50 focus:border-2 focus:border-primary"
          >
            {statusArray &&
              statusArray.map((b, i) => (
                <option
                  key={i}
                  className={`text-lg h-10 ${
                    b?._id == status ? "bg-primary text-white" : ""
                  }`}
                  value={b}
                >
                  {b}
                </option>
              ))}
          </select>
        </div>
        <div className="w-[25%]">
        <div>Select type :</div>
          <select
            onChange={(e) => setType(e.target.value)}
            id="small"
            class="w-full h-10 p-1 text-lg border border-gray-300 rounded-lg bg-gray-50 focus:border-2 focus:border-primary"
          >
            {typeArray &&
              typeArray.map((b, i) => (
                <option
                  key={i}
                  className={`text-lg h-10 ${
                    b?._id == type ? "bg-primary text-white" : ""
                  }`}
                  value={b}
                >
                  {b}
                </option>
              ))}
          </select>
        </div>
        <div className="w-[25%]">
        <div>Select Date :</div>
          <input
            type="date"
            onChange={(e) => setDate(e.target.value)}
            class="w-full h-10 p-1 text-lg border border-gray-300 rounded-lg bg-gray-50 focus:border-2 focus:border-primary"
          />
        </div>
      </div>

      <div className="flex justify-end items-center mt-4">
        <div
          onClick={() => setAddSessionPopupShow(true)}
          class="flex items-center text-white gap-2 bg-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center cursor-pointer"
        >
          <FaPlus /> Add Session
        </div>
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center h-[50vh]">
          <div class="w-16 h-16 border-8 border-dashed rounded-full animate-spin border-primary"></div>
        </div>
      ) : filterArray.length > 0 ? (
        <SessionTable data={filterArray} reload={getAllSessions} />
      ) : (
        <div className="flex justify-center items-center h-[50vh]">
          <div class="text-xl">Data Not Found.</div>
        </div>
      )}

      {addSessionPopupShow && (
        <AddSessionsModel
          show={addSessionPopupShow}
          close={setAddSessionPopupShow}
          reload={getAllSessions}
        />
      )}
    </div>
  );
};

export default CounselorSessions;
