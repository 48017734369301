import React, { useEffect, useState } from "react";
import { API } from "../../API";
import { FaPlus } from "react-icons/fa";
import CourseTable from "../tables/course/CourseTable";
import AddCourseModel from "../models/course/AddCourseModel";

const SuperAdminCourse = () => {
  const [addCoursePopupShow, setAddCoursePopupShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState("ALL");
  const [branch, setBranch] = useState([]);
  const [filterArray, setFilterArray] = useState([]);

  const getAllBranch = async () => {
    try {
      const { data } = await API.get("/branch/get");
      setBranch(data.data.branch);
    } catch (error) {
      console.log("error in getAllBranch :", error);
    }
  };

  useEffect(() => {
    getAllBranch();
  }, []);

  const getAllCourse = async () => {
    try {
      setIsLoading(true);
      const { data } = await API.get(`/course/get/${selectedBranch}`);
      setFilterArray(data.data.course);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error in getAllCourse :", error);
    }
  };

  useEffect(() => {
    getAllCourse();
  }, [selectedBranch]);
  return (
    <div className="p-4">
      <div className="flex justify-between items-center">
        <div className="w-[25%]">
          <select
            id="small"
            onChange={(e) => setSelectedBranch(e.target.value)}
            class="w-full h-10 p-1 text-lg border border-gray-300 rounded-lg bg-gray-50 focus:border-2 focus:border-primary"
          >
            <option
              className={`text-lg h-10 ${
                "ALL" == selectedBranch ? "bg-primary text-white" : ""
              }`}
              value="ALL"
            >
              All
            </option>
            {branch &&
              branch.map((b, i) => (
                <option
                  key={i}
                  className={`text-lg h-10 ${
                    b?._id == selectedBranch ? "bg-primary text-white" : ""
                  }`}
                  value={b?._id}
                >
                  {b?.name}
                </option>
              ))}
          </select>
        </div>
        <div
          onClick={() => setAddCoursePopupShow(true)}
          class="flex items-center text-white gap-2 bg-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center cursor-pointer"
        >
          <FaPlus /> Add Course
        </div>
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center h-[50vh]">
          <div class="w-16 h-16 border-8 border-dashed rounded-full animate-spin border-primary"></div>
        </div>
      ) : filterArray.length > 0 ? (
        <CourseTable data={filterArray} reload={getAllCourse} />
      ) : (
        <div className="flex justify-center items-center h-[50vh]">
          <div class="text-xl">Data Not Found.</div>
        </div>
      )}
      <AddCourseModel
        show={addCoursePopupShow}
        close={setAddCoursePopupShow}
        reload={getAllCourse}
      />
    </div>
  );
};

export default SuperAdminCourse;
